import React, {useEffect, useState} from 'react';
import './Welcome.css'
import banner from '../assets/banner.png'
import banner_full from '../assets/banner_full.png'
import qr from '../assets/qr.svg'
import spinner from '../assets/spinner.svg'

const DesktopBlock = ({visible}) => {

    return (

        <div className={`${visible ? 'visible' : ''} welcome-wrapper`}>

            <img src={banner_full} className={'welcome-banner'} />

            <h4>
                Play TrumPump..
            </h4>

            <h1>
                On Mobile
            </h1>


            <div className={'desktop-block-qr-container'}>
                <img src={qr} className={'desktop-block-qr'} />
            </div>


            <div className={'banner-btn'}>
                <h2>
                    Scan QR
                </h2>
            </div>

            <p>
                t.me/trumpumpbot
            </p>


        </div>
    );
};

export default DesktopBlock;