import React, {useState, useEffect} from 'react';
import axios from "axios";
import flag from "../assets/flag.png";

import vote_gold from '../assets/vote_gold.png';


const DebtModal = ({debtModal, setDebtModal, debtInvestModal, setDebtInvestModal, urlNew, authToken, authStatus}) => {

    const [usDebt, setUsDebt] = useState(0);
    const [usDebtChanged, setUsDebtChanged] = useState(0);
    const [usDebtPaid, setUsDebtPaid] = useState(0);

    // const usDebtUrl = `https://api.fiscaldata.treasury.gov/services/api/fiscal_service/v2/accounting/od/debt_to_penny?fields=tot_pub_debt_out_amt&filter=record_date:gte:2024-${month < 10 ? '0' + month : month}-${(day - 3) < 10 ? '0' + (day - 3) : (day - 3)}`;
    const usDebtUrl = `https://api.fiscaldata.treasury.gov/services/api/fiscal_service/v2/accounting/od/debt_to_penny?fields=tot_pub_debt_out_amt&filter=record_date:gte:2024-07-15`;

    const getUsDebt = () => {

        axios.get(`${urlNew}/api/v1/treasury`, {
            headers: {
                "X-Auth-Token": authToken.current,
            }
        })
            .then((response) => {
                setUsDebt(response.data.current_debt);
                setUsDebtPaid(response.data.user_coins);
                // setUsDebt(response.data.data[0].tot_pub_debt_out_amt);
            })
            .catch(error => {
                // alert(error);
                console.log(error);

                setTimeout(() => {
                    getUsDebt();
                }, 3000)

                // setErrorMessage(true);
            });
    };


    useEffect(() => {
        getUsDebt();
    }, [debtInvestModal])


    /*useEffect(() => {

        const interval = setInterval(() => {

            if ((usDebtChanged < 35500000000000) && (usDebtChanged > 33500000000000)) {
                setUsDebtChanged(Number(34911647554064) + (Date.now() * 30.70776219) - 52822200000000);
                setUsDebtPaid(usDebtPaid + (Date.now() * 30.771576219) - 52822200000000);
            } else {
                setUsDebtChanged(usDebt);
                setUsDebtPaid(1439590324080);
            }

        }, 1000);

        return () => {
            clearInterval(interval);
        }

    }, [debtModal]);*/


    return (
        <div className={`${debtModal ? 'active' : ''} modal-wrapper`}>

            <div className={`${debtModal ? 'active' : ''} modal-overlay`} onClick={() => setDebtModal(false)}></div>

            <div className={`${debtModal ? 'active' : ''} modal-container`}>

                <div className={`${debtModal ? 'active' : ''} centered modal-content`}>

                    <h4 className={'modal-content-header'}>
                        U.S. National Debt
                    </h4>

                    <h3 className={''}>
                        ${(usDebt)?.toLocaleString()}
                    </h3>

                    <div className={'modal-debt-line-container'}>

                        <div>
                            <p>
                                Total Amount Contributed
                            </p>
                            <p className={''} style={{marginTop: '2px'}}>
                                ${(Math.round(usDebtPaid))?.toLocaleString()}
                            </p>
                        </div>

                        <div>
                            <p>
                                Remaining
                            </p>
                            <p className={''} style={{marginTop: '2px'}}>
                                ${(Math.round(usDebt - usDebtPaid))?.toLocaleString()}
                            </p>
                        </div>

                    </div>


                    <div className={'play-play-line-container'}>
                        <div
                            className={'play-play-line'}
                            style={{
                                width: `${100 - ((usDebt - usDebtPaid) / usDebt * 100)}%`,
                            }}
                        ></div>
                    </div>


                    <div className={'modal-debt-img-container'}>
                        <img src={flag} className={'modal-debt-img'} />
                        <div className={'modal-debt-img-shadow'}></div>
                    </div>


                    <div className={'modal-content-p start'}>
                        The US national debt crisis is a growing concern, with debt levels reaching unprecedented heights and threatening economic stability. This financial burden affects everyone, limiting resources and future opportunities. In the first season of Trumpump, we are focusing on tackling the debt issue.
                    </div>


                    </div>

                    <button
                        className={'modal btn round full primary'}
                    onClick={() => {
                        setDebtModal(false)
                        setTimeout(() => {
                            setDebtInvestModal(true)
                        }, 500)
                    }}
                >
                    Pay off U.S. National Debt
                </button>

                    <button
                        className={'modal btn round full white'}
                        onClick={() => {
                            setDebtModal(false)
                        }}
                        style={{marginTop: '12px'}}
                >
                        Continue to PUMP
                </button>

            </div>

        </div>
    );
};

export default DebtModal;