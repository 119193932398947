// import blank from "./assets/blank.png";

// 1
/*import bogdanoff from "./assets/blank_maga.png";
import blender from "./assets/blank_maga.png";
import hamster from "./assets/blank_maga.png";
import reptilian from "./assets/blank_maga.png";
import blank_maga from "./assets/blank_maga.png";*/


import m1 from "./assets/farm/1/1_1_Vitalik Buterin.JPG";
import m2 from "./assets/farm/1/1_2_Brian Armstrong.JPG";
import m3 from "./assets/farm/1/1_3_Sam Bankman-Fried.JPG";
import m4 from "./assets/farm/1/1_4_Bogdanoff Twins.JPG";
import m5 from "./assets/farm/1/1_5_Roger Ver.JPG";
import m6 from "./assets/farm/1/1_6_Charlie Lee.JPG";
import m7 from "./assets/farm/1/1_7_Charles Hoskinson.JPG";
import m8 from "./assets/farm/1/1_8_Snoop Dogg.JPG";
import m9 from "./assets/farm/1/1_9_Michael J. Saylor.JPG";

import m10 from "./assets/farm/1/2_1_Donald Trump Jr..JPG";
// import m11 from "./assets/farm/1/2_1_Donald Trump Jr..JPG";
import m12 from "./assets/farm/1/2_2_Winklevoss Twins.JPG";
import m13 from "./assets/farm/1/2_3_Satoshi Nakamoto.JPG";
import m14 from "./assets/farm/1/2_4_Anatoly Yakovenko.JPG";
import m15 from "./assets/farm/1/2_5_Larry Fink.JPG";
import m16 from "./assets/farm/1/2_6_Ross Ulbricht.JPG";
import m17 from "./assets/farm/1/2_7_John McAfee.JPG";
import m18 from "./assets/farm/1/2_8_Do Kwon.JPG";
import m19 from "./assets/farm/1/2_9_Changpeng Zhao.JPG";
// import m20 from "./assets/farm/1/3_1_Justin Sun.JPG";

import m21 from "./assets/farm/1/3_1_Justin Sun.JPG";
import m22 from "./assets/farm/1/3_2_Jay-Z.JPG";
import m23 from "./assets/farm/1/3_3_Pavel Durov.JPG";
import m24 from "./assets/farm/1/3_4_Hamster.JPG";
import m25 from "./assets/farm/1/3_5_Elon Musk.JPG";
import m26 from "./assets/farm/1/3_6_Alexander Vinnik.JPG";
import m27 from "./assets/farm/1/3_7_Laszlo Hanyecz.JPG";
import m28 from "./assets/farm/1/3_8_Mark Zuckerberg.JPG";
import m29 from "./assets/farm/1/3_9_Gary Gensler.JPG";

import m31 from "./assets/farm/1/4_1_Nayib Bukele.JPG";
import m32 from "./assets/farm/1/4_2_Craig Wright.JPG";
import m33 from "./assets/farm/1/4_3_JD Vance.JPG";
import m34 from "./assets/farm/1/4_4_Charlie Shrem.JPG";
import m35 from "./assets/farm/1/4_5_Brad Garlinghouse.JPG";
import m36 from "./assets/farm/1/4_6_Andreas Antonopoulos.JPG";
import m37 from "./assets/farm/1/4_7_Joseph Lubin.JPG";
import m38 from "./assets/farm/1/4_8_Ryan Selkis.JPG";
import m39 from "./assets/farm/1/4_9_Nick Szabo.JPG";

import m41 from "./assets/farm/1/5_1_Jed McCaleb.JPG";
import m42 from "./assets/farm/1/5_2_Daniel Larimer.JPG";
import m43 from "./assets/farm/1/5_3_Hester Peirce.JPG";
import m44 from "./assets/farm/1/5_4_DOGE.JPG";
import m45 from "./assets/farm/1/5_5_Keith Gill.JPG";
import m46 from "./assets/farm/1/5_6_Hal Finney.JPG";
import m47 from "./assets/farm/1/5_7_Martti Malmi.JPG";
import m48 from "./assets/farm/1/5_8_Whitfield Diffie.JPG";
import m49 from "./assets/farm/1/5_9_PEPE.JPG";




// 2
import magazine from "./assets/farm/2/magazine.png";
import book from "./assets/farm/2/book.png";
import tv from "./assets/farm/2/tv.png";
import bitcoin from "./assets/farm/2/bitcoin.png";
import consult from "./assets/farm/2/consult.png";
import charity from "./assets/farm/2/charity.png";
import meeting from "./assets/farm/2/meeting.png";
import create_crypto from "./assets/farm/2/create_crypto.png";
import build_mining from "./assets/farm/2/build_mining.png";
import start_exchange from "./assets/farm/2/start_exchange.png";
import speech from "./assets/farm/2/speech.png";

// 3
import ferrari from "./assets/farm/3/ferrari.png";
import diablo from "./assets/farm/3/diablo.png";
import mclaren from "./assets/farm/3/mclaren.png";
import acura from "./assets/farm/3/acura.png";
import allante from "./assets/farm/3/allante.png";
import boeing from "./assets/farm/3/boeing.png";
import chopper from "./assets/farm/3/chopper.png";
import eldorado from "./assets/farm/3/eldorado.png";
import fleetwood from "./assets/farm/3/fleetwood.png";
import helicopter from "./assets/farm/3/helicopter.png";
import merc_sl from "./assets/farm/3/merc_sl.png";
import phantom from "./assets/farm/3/phantom.png";
import rr_silver from "./assets/farm/3/rr_silver.png";

// 4
import trump_book from "./assets/farm/4/book.png";
import golf from "./assets/farm/4/golf.png";
import hotel from "./assets/farm/4/hotel.png";
import keys from "./assets/farm/4/keys.png";
import sneaker from "./assets/farm/4/sneaker.png";
import tower from "./assets/farm/4/tower.png";
import vodka from "./assets/farm/4/vodka.png";
import wine from "./assets/farm/4/wine.png";


export const trumpologySchema = [

    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',

];


/*

activation_trigger_type: 1 = MAGA Prev Class
activation_trigger_type: 2 = Different Card
activation_trigger_type: 3 = TRUMP Level
activation_trigger_type: 3 = Friends

*/



export const farmSchema = [


    // Bronze

    {
        id: 501,
        name: 'Vitalik Buterin',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m1,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1, // MAGA Prev Class
        activation_trigger_goal: 3, // 3 Prev Cards
        activation_trigger_amount: 3,
    },

    {
        id: 502,
        name: 'Brian Armstrong',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m2,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 503,
        name: 'Sam Bankman-Fried',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m3,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 504,
        name: 'Bogdanoff Twins',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m4,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 505,
        name: 'Roger Ver',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m5,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 506,
        name: 'Charlie Lee',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m6,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 507,
        name: 'Charles Hoskinson',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m7,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 508,
        name: 'Snoop Dogg',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m8,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 509,
        name: 'Michael J. Saylor',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m9,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    /*{
        id: 510,
        name: 'Biden is a Reptilian!',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: reptilian,
        type: 1,
        class: 1,
        farm: 100,
        price: 3000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },*/


    // Silver

    {
        id: 511,
        name: 'Donald Trump Jr.',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m10,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 512,
        name: 'Changpeng Zhao',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m19,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 513,
        name: 'Winklevoss Twins',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m12,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 514,
        name: 'Satoshi Nakamoto',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m13,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 515,
        name: 'Anatoly Yakovenko',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m14,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 516,
        name: 'Larry Fink',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m15,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 517,
        name: 'Ross Ulbricht',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m16,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 518,
        name: 'John McAfee',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m17,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 519,
        name: 'Do Kwon',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m18,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    /*{
        id: 520,
        name: 'Biden is a Reptilian!',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: reptilian,
        type: 1,
        class: 2,
        farm: 275,
        price: 10000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },*/


    // Gold

    {
        id: 521,
        name: 'Justin Sun',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m21,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 522,
        name: 'Jay-Z',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m22,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 523,
        name: 'Pavel Durov',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m23,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 524,
        name: 'Hamster',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m24,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 525,
        name: 'Elon Musk',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m25,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 526,
        name: 'Alexander Vinnik',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m26,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 527,
        name: 'Laszlo Hanyecz',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m27,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 528,
        name: 'Mark Zuckerberg',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m28,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 529,
        name: 'Gary Gensler',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m29,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    /*{
        id: 530,
        name: 'Biden is a Reptilian!',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: reptilian,
        type: 1,
        class: 3,
        farm: 600,
        price: 30000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },*/


    // Platinum

    {
        id: 531,
        name: 'Nayib Bukele',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m31,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 532,
        name: 'Craig Wright',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m32,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 533,
        name: 'JD Vance',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m33,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 534,
        name: 'Charlie Shrem',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m34,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 535,
        name: 'Brad Garlinghouse',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m35,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 536,
        name: 'Andreas Antonopoulos',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m36,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 537,
        name: 'Joseph Lubin',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m37,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 538,
        name: 'Ryan Selkis',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m38,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 539,
        name: 'Nick Szabo',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m39,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    /*{
        id: 540,
        name: 'Trump Kombat',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: hamster,
        type: 1,
        class: 4,
        farm: 1000,
        price: 75000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },*/


    // Diamond

    {
        id: 541,
        name: 'Jed McCaleb',
        description: 'The Bogdanoff Brothers look from heaven at how Trump pushes crypto',
        img: m41,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 542,
        name: 'Daniel Larimer',
        description: 'Trump pours a lot of money into a huge blender that pumps everything into Bitcoin',
        img: m42,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 543,
        name: 'Hester Peirce',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m43,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 544,
        name: 'DOGE',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m44,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 545,
        name: 'Keith Gill',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m45,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 546,
        name: 'Hal Finney',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m46,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 547,
        name: 'Martti Malmi',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m47,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 548,
        name: 'Whitfield Diffie',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: m48,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 549,
        name: 'PEPE',
        description: 'Trump in a cheap plastic Hamster Kombat mask',
        img: m49,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 1,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    /*{
        id: 550,
        name: 'Biden is a Reptilian!',
        description: 'Trump took off Biden\'s mask in there reptilian',
        img: reptilian,
        type: 1,
        class: 5,
        farm: 1750,
        price: 150000,
        farm_koef: 1,
        price_koef: 1,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },*/
























    {
        id: 201,
        name: 'Newspaper',
        description: 'Publish a newspaper article',
        img: magazine,
        type: 2,
        class: 0,
        farm: 85,
        price: 1200,
        farm_koef: 1.15,
        price_koef: 1.45,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 202,
        name: 'Book',
        description: 'Publish a new book',
        img: book,
        type: 2,
        class: 0,
        farm: 320,
        price: 4200,
        farm_koef: 1.2,
        price_koef: 1.58,
        activation_trigger_type: 2,
        activation_trigger_goal: 201,
        activation_trigger_amount: 3,
    },

    {
        id: 203,
        name: 'TV',
        description: 'Give an interview on television',
        img: tv,
        type: 2,
        class: 0,
        farm: 142,
        price: 1620,
        farm_koef: 1.21,
        price_koef: 1.62,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 204,
        name: 'BTC Pump',
        description: 'Promote the growth of Bitcoin',
        img: bitcoin,
        type: 2,
        class: 0,
        farm: 215,
        price: 7200,
        farm_koef: 1.27,
        price_koef: 1.65,
        activation_trigger_type: 2,
        activation_trigger_goal: 205,
        activation_trigger_amount: 5,
    },

    {
        id: 205,
        name: 'Expert Opinion',
        description: 'Get advice from cryptocurrency experts',
        img: consult,
        type: 2,
        class: 0,
        farm: 105,
        price: 1360,
        farm_koef: 1.13,
        price_koef: 1.42,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 206,
        name: 'Charity',
        description: 'Hold a charity event',
        img: charity,
        type: 2,
        class: 0,
        farm: 48,
        price: 624,
        farm_koef: 1.25,
        price_koef: 1.72,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 207,
        name: 'Rally',
        description: 'Hold a rally with voters',
        img: meeting,
        type: 2,
        class: 0,
        farm: 224,
        price: 1840,
        farm_koef: 1.1,
        price_koef: 1.38,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 208,
        name: 'Cryptocurrency',
        description: 'Launch your own cryptocurrency',
        img: create_crypto,
        type: 2,
        class: 0,
        farm: 322,
        price: 12400,
        farm_koef: 1.21,
        price_koef: 1.63,
        activation_trigger_type: 2,
        activation_trigger_goal: 209,
        activation_trigger_amount: 5,
    },

    {
        id: 209,
        name: 'Mining Farm',
        description: 'Build a mining farm',
        img: build_mining,
        type: 2,
        class: 0,
        farm: 284,
        price: 2418,
        farm_koef: 1.24,
        price_koef: 1.71,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 210,
        name: 'Cryptoexchange',
        description: 'Launch your own cryptocurrency exchange',
        img: start_exchange,
        type: 2,
        class: 0,
        farm: 410,
        price: 7868,
        farm_koef: 1.22,
        price_koef: 1.53,
        activation_trigger_type: 2,
        activation_trigger_goal: 211,
        activation_trigger_amount: 5,
    },

    {
        id: 211,
        name: 'Statement',
        description: 'Make a bold statement',
        img: speech,
        type: 2,
        class: 0,
        farm: 65,
        price: 770,
        farm_koef: 1.34,
        price_koef: 1.82,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },
















    {
        id: 301,
        name: 'Ferrari F430',
        description: 'Buy a unique car',
        img: ferrari,
        type: 3,
        class: 0,
        farm: 880,
        price: 100000,
        farm_koef: 1.4,
        price_koef: 2,
        activation_trigger_type: 3,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 302,
        name: 'Rolls-Royce Phantom',
        description: 'Buy a unique car',
        img: phantom,
        type: 3,
        class: 0,
        farm: 1860,
        price: 150000,
        farm_koef: 1.17,
        price_koef: 1.8,
        activation_trigger_type: 3,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 303,
        name: 'Mercedes-Benz SLR McLaren',
        description: 'Buy a unique car',
        img: mclaren,
        type: 3,
        class: 0,
        farm: 3400,
        price: 240000,
        farm_koef: 1.2,
        price_koef: 2.4,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 304,
        name: 'Cadillac Eldorado',
        description: 'Buy a unique car',
        img: eldorado,
        type: 3,
        class: 0,
        farm: 1800,
        price: 360000,
        farm_koef: 1.2,
        price_koef: 1.45,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 305,
        name: 'Lamborghini Diablo Roadster',
        description: 'Buy a unique car',
        img: diablo,
        type: 3,
        class: 0,
        farm: 2680,
        price: 275400,
        farm_koef: 1.15,
        price_koef: 1.62,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 306,
        name: 'Rolls-Royce Silver Cloud',
        description: 'Buy a unique car',
        img: rr_silver,
        type: 3,
        class: 0,
        farm: 1750,
        price: 440000,
        farm_koef: 1.12,
        price_koef: 1.37,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 307,
        name: 'Cadillac Fleetwood 1975',
        description: 'Buy a unique car',
        img: fleetwood,
        type: 3,
        class: 0,
        farm: 1475,
        price: 328000,
        farm_koef: 1.2,
        price_koef: 1.48,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 308,
        name: 'Mercedes-Benz 560 SL',
        description: 'Buy a unique car',
        img: merc_sl,
        type: 3,
        class: 0,
        farm: 6420,
        price: 716400,
        farm_koef: 1.05,
        price_koef: 1.66,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 309,
        name: 'Cadillac Allante',
        description: 'Buy a unique car',
        img: allante,
        type: 3,
        class: 0,
        farm: 960,
        price: 124200,
        farm_koef: 1.22,
        price_koef: 1.65,
        activation_trigger_type: 3,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 309,
        name: 'Acura NSX 1991',
        description: 'Buy a unique car',
        img: acura,
        type: 3,
        class: 0,
        farm: 1740,
        price: 331700,
        farm_koef: 1.22,
        price_koef: 1.77,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 310,
        name: 'Custom Gold Bike',
        description: 'Buy a unique bike',
        img: chopper,
        type: 3,
        class: 0,
        farm: 1480,
        price: 92800,
        farm_koef: 1.12,
        price_koef: 1.6,
        activation_trigger_type: 3,
        activation_trigger_goal: 3,
        activation_trigger_amount: 3,
    },

    {
        id: 311,
        name: 'Helicopter',
        description: 'Buy a helicopter',
        img: helicopter,
        type: 3,
        class: 0,
        farm: 2815,
        price: 682300,
        farm_koef: 1.1,
        price_koef: 1.42,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 311,
        name: 'Boeing 747',
        description: 'Buy a plane',
        img: boeing,
        type: 3,
        class: 0,
        farm: 7415,
        price: 1200000,
        farm_koef: 1.02,
        price_koef: 1.68,
        activation_trigger_type: 3,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },
















    {
        id: 401,
        name: 'Trump Sneakers',
        description: 'Exclusive luxury item',
        img: sneaker,
        type: 4,
        class: 0,
        farm: 2450,
        price: 1250000,
        farm_koef: 1.17,
        price_koef: 1.35,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 402,
        name: 'Trump Vodka',
        description: 'Exclusive luxury item',
        img: vodka,
        type: 4,
        class: 0,
        farm: 3420,
        price: 1620000,
        farm_koef: 1.01,
        price_koef: 1.66,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 403,
        name: 'Trump: The Art of the Deal',
        description: 'Exclusive luxury item',
        img: trump_book,
        type: 4,
        class: 0,
        farm: 1900,
        price: 840000,
        farm_koef: 1.03,
        price_koef: 1.37,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 404,
        name: 'Trump Winery',
        description: 'Exclusive luxury item',
        img: wine,
        type: 4,
        class: 0,
        farm: 1240,
        price: 1420000,
        farm_koef: 1.3,
        price_koef: 1.51,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

    {
        id: 405,
        name: 'Trump Park Avenue',
        description: 'Exclusive luxury item',
        img: tower,
        type: 4,
        class: 0,
        farm: 4460,
        price: 1575000,
        farm_koef: 1.04,
        price_koef: 1.71,
        activation_trigger_type: 4,
        activation_trigger_goal: 5,
        activation_trigger_amount: 5,
    },

    {
        id: 406,
        name: 'Trump International Hotel',
        description: 'Exclusive luxury item',
        img: hotel,
        type: 4,
        class: 0,
        farm: 7700,
        price: 3200000,
        farm_koef: 1.06,
        price_koef: 1.42,
        activation_trigger_type: 4,
        activation_trigger_goal: 30,
        activation_trigger_amount: 30,
    },

    {
        id: 407,
        name: 'Mar-a-Lago',
        description: 'Exclusive luxury item',
        img: keys,
        type: 4,
        class: 0,
        farm: 5620,
        price: 2250000,
        farm_koef: 1.06,
        price_koef: 1.45,
        activation_trigger_type: 4,
        activation_trigger_goal: 10,
        activation_trigger_amount: 10,
    },

    {
        id: 408,
        name: 'Trump National Golf Club Bedminster',
        description: 'Exclusive luxury item',
        img: golf,
        type: 4,
        class: 0,
        farm: 2120,
        price: 880000,
        farm_koef: 1.08,
        price_koef: 1.58,
        activation_trigger_type: 0,
        activation_trigger_goal: 0,
        activation_trigger_amount: 0,
    },

];





export const farm = [

    /*{
        id: 5,
        name: 'MAGA Hat',
        description: 'Protects from liberal soy-boys and gives +1 to PUMPing power.',
        img: maga,
        influence: 'active',
        value: 1,
        price: 500,
        quantity: 100,
    },*/

];









for (let i = 0; i < farmSchema.length; i++) {

    let farmLevels = [];

    let price_container = farmSchema[i].price;
    let price_counter = price_container;
    let price_total_counter = farmSchema[i].price;

    let farm_container = farmSchema[i].farm;
    let farm_counter = farm_container;
    let farm_total_counter = farmSchema[i].farm;

    for (let j = 0; j < 61; j++) {

        if (j !== 0) {

            price_counter = price_counter * farmSchema[i].price_koef;
            farm_counter = farm_counter * farmSchema[i].farm_koef;

            price_total_counter = price_total_counter + price_counter;
            farm_total_counter = farm_total_counter + farm_counter;
        }

        farmLevels.push(
            {
                level: j,
                price: price_counter,
                farm: farm_counter,
                total_price: price_total_counter,
                total_farm: farm_total_counter,
            }
        );

    }

    farm.push(
        {
            ...farmSchema[i],
            farmLevels
        }
    );

}

// console.log(farm);

let farma = [];

/*for (let i = 0; i < farm.length; i++) {
    farma.push({
        // id: farm[i].id,
        farmLevels: farm[i].farmLevels
    })
}*/

// console.log(JSON.stringify(farma))












































/*

old ==> new

farm (only income/hour) upgrade_id:
1xx ==> 5xx
2xx ==> 2xx
3xx ==> 3xx
4xx ==> 4xx

boost (only Multi PUMP && Energy Limit) user field to upgrade_id:
boost_one_level ==> 1
boost_two_level ==> 2



*/






















