import React, {useEffect, useReducer, useRef, useState} from 'react';
import Head from "./Head";
import PlayBalance from "./PlayBalance";
import help from "../assets/boosters/energy_drink.png";

import trump1 from "../assets/trump_levels/trump1.png";
import trump4 from "../assets/trump_levels/trump4.png";
import trump3 from "../assets/trump_levels/trump3.png";
import trump2 from "../assets/trump_levels/trump2.png";
import trump5 from "../assets/trump_levels/trump5.png";
import trump6 from "../assets/trump_levels/trump6.png";
import trump7 from "../assets/trump_levels/trump7.png";
import trump8 from "../assets/trump_levels/trump8.png";
import trump9 from "../assets/trump_levels/trump9.png";
import trump10 from "../assets/trump_levels/trump10.png";

import calendar from "../assets/rewards/calendar.png";
import energy_img from "../assets/nav/energy.png";
import rage_mode from "../assets/boosters/rage_mode.png";
import rocket from "../assets/nav/rocket.png";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

import Lottie from 'react-lottie';
import TrumpLottie from "./TrumpLottie";
import axios from "axios";


let state = {};



const Main = ({
                  trumpLevelState,
                  setTrumpLevelState,
                  animationMapping,
                  levelChangeModal,
                  authToken,
                  urlNew,
                  setErrorMessage,
                  setErrorMessageText,
                  setDebtModal,
                  setDebtInvestModal,
                  rageModeCounter,
                  energy,
                  energyMax,
                  voteBtnRef,
                  rageMode,
                  trumpLevel,
                  boostOpen,
                  setBoostOpen,
                  selectedItem,
                  setSelectedItem,
                  trumpLevelProps,
                  counter,
                  counterTemp,
                  counterState,
                  tokensPerClick,
                  level,
                  tokensPerHour,
                  checkPointLoading,
                  isRageMode,
                  changeNav,
                  setBoostsOpen,
                  setLevelsModal
              }) => {


    const url = urlNew;

    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const [d, sd] = useState(0)

    useEffect(() => {

        sd(d+1);

        forceUpdate();

        sd(p => p + 1);

    }, [levelChangeModal]);


    const [counterCounter, setCounterCounter] = useState(false);


    useEffect(() => {
        setRipples([]);
    }, [selectedItem, boostOpen])

    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();

    const [ripples, setRipples] = useState([]);
    let [rippleCounter, setRippleCounter] = useState(0);

    const interactiveElementSelector = 'a, button';
    const ref = useRef(null);

    const handleRipple = (e) => {
        const rect = e.target.getBoundingClientRect();

        let time = e.timeStamp;

        for (let i = 0; i < e.targetTouches?.length; i++) {
            const x = e.targetTouches[i].clientX - rect.left;
            const y = e.targetTouches[i].clientY - rect.top;
            setRipples([
                ...ripples,
                {
                    id: time,
                    x,
                    y,
                },
            ])
        }

    };


    const discardRipple = (rippleId) => {
        setRipples((ripples) => ripples.filter((ripple) => ripple.id !== rippleId))
    };







    const trackTap = () => {

        let time = Math.floor(Date.now() / 1000).toString();

        if (state[time] === undefined) {
            state[time] = 1
        } else {
            state[time]++;
        }

    }

    const syncTaps = () => {

        if (authToken.current !== undefined) {

            axios.post(`${urlNew}/api/v1/state/sync`, {"taps": state}, {
                headers: {
                    "X-Auth-Token": authToken.current,
                },
            })
                .then(response => {
                    state = {};
                    counter.current = response.data.coins;
                    energy.current = response.data.energy;
                })
                .catch(error => {
                    // alert(error);
                    setErrorMessageText('Sync Failed');
                    setErrorMessage(true);
                });

        }

    };




    useEffect(() => {

        const interval = setInterval(() => {

            syncTaps();

        }, 6000)

        return () => {
            clearInterval(interval);
        }

    }, [])




    let lottieDefaultOptions = {
        loop: false,
        autoplay: false,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            progressiveLoad: true,
        },
        mode:"cursor",
        actions: [
            {
                type: "click",
                forceFlag: false
            }
        ],
        isClickToPauseDisabled: true,
    };






    return (
        <div className={`${(selectedItem === 3) && (!boostOpen) ? 'active' : ''} play-page-block1`} id={'play-page'}>

            <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal} />

            <PlayBalance
                trumpLevelProps={trumpLevel}
                counter={counter}
                counterTemp={counterTemp}
                counterState={counterCounter}
                tokensPerClick={!rageMode ? tokensPerClick : tokensPerClick*3}
                tokensPerHour={tokensPerHour}
                checkPointLoading={checkPointLoading}
                isRageMode={rageMode}
                changeNav={setSelectedItem}
                setBoostsOpen={setBoostOpen}
                setLevelsModal={setLevelsModal}
            />




            <div className={'add-btns-container'}>

                <div
                    className={'debt-container'}
                    onClick={() => setDebtModal(true)}
                >

                    <p>
                        {/*$7,439M of $34,818M*/}
                        U.S. National Debt
                    </p>

                    {/*<img src={help} />*/}

                </div>

                {/*<div
                    className={'debt-container blue'}
                    onClick={() => setDebtInvestModal(true)}
                >

                    <p>
                        $7,439M of $34,818M
                        Pay off
                    </p>

                    <img src={calendar} />

                </div>*/}

            </div>







            <div
                id={'vote-btn'}
                ref={voteBtnRef}
                className={`${(energy.current >= tokensPerClick) || (rageMode) ? '' : 'blocked'} ${!boostOpen ? 'in-view' : ''} png vote-btn primary`}
                onTouchStart={(e) => {
                    handleRipple(e);

                    counter.current = counter.current + (!rageMode ? tokensPerClick : tokensPerClick*3);
                    if (!rageMode) {energy.current = energy.current - tokensPerClick};
                    setCounterCounter(!counterCounter);

                    trackTap();

                    selectionChanged();
                }}
                onTouchEnd={() => setCounterCounter(!counterCounter)}
            >
                {
                    trumpLevel.current < 0 ?
                        <TrumpLottie
                            lottieDefaultOptions={lottieDefaultOptions}
                            levelChangeModal={levelChangeModal}
                            trumpLevel={trumpLevel}
                            url={urlNew}
                            animPath={animationMapping[trumpLevel.current]}
                            animPathStatic={animationMapping[trumpLevel.current + 10]}
                        />
                        :
                        ''
                }
                <img className={`${trumpLevel.current === 1 ? 'active' : ''} png btcs-img`} src={trump6} />
                <img className={`${trumpLevel.current === 2 ? 'active' : ''} png btcs-img`} src={trump7} />
                <img className={`${trumpLevel.current === 3 ? 'active' : ''} png btcs-img`} src={trump4} />
                <img className={`${trumpLevel.current === 4 ? 'active' : ''} png btcs-img`} src={trump3} />
                <img className={`${trumpLevel.current === 5 ? 'active' : ''} png btcs-img`} src={trump5} />
                <img className={`${trumpLevel.current === 6 ? 'active' : ''} png btcs-img`} src={trump2} />
                <img className={`${trumpLevel.current === 7 ? 'active' : ''} png btcs-img`} src={trump8} />
                <img className={`${trumpLevel.current === 8 ? 'active' : ''} png btcs-img`} src={trump1} />
                <img className={`${trumpLevel.current === 9 ? 'active' : ''} png btcs-img`} src={trump10} />
                <img className={`${trumpLevel.current === 10 ? 'active' : ''} png btcs-img`} src={trump9} />
            </div>

            <div className={'png vote-btn-ripple-container'} id={'ripple-container'}>
                {
                    ripples.map((ripple) =>
                        <div
                            key={ripple.id}
                            style={{
                                left: `${ripple.x + (tokensPerClick < 10 ? 190 : tokensPerClick < 100 ? 175 : 155)}px`,
                                top: `${ripple.y - 10}px`,
                                color: rageMode ? '#ffffff' : '#ffffff',
                                fontSize: rageMode ? '48px' : '',
                                transform: rageMode ? '' : '',
                            }}
                            className={`vote-btn-ripple`}
                            onAnimationEnd={() => {
                                discardRipple(ripple)
                            }}
                            onAnimationEndCapture={() => {
                                discardRipple(ripple)
                            }}
                        >+{!rageMode ? tokensPerClick : tokensPerClick*3}</div>
                    )
                }
            </div>





            <div className={'play-bottom-container'}>

                <div
                    className={`play-bottom-energy-container`}
                >

                    <img src={energy_img} />

                    <div className={`play-bottom-energy-text`}>
                        <h6 className={'mono'}>
                            {
                                (energy.current)?.toLocaleString(undefined, {maximumFractionDigits: 0})
                            }
                        </h6>
                        <p>
                            {
                                `Out Of ${energyMax?.toLocaleString(undefined, {maximumFractionDigits: 0})}`
                            }
                        </p>
                    </div>

                </div>


                <div
                    className={`${rageMode ? 'active' : ''} rage blue play-bottom-energy-container`}
                >

                    <img src={rage_mode} style={{transform: 'scale(125%) translateX(-2px)'}} />

                    <div className={`blue play-bottom-energy-text`}>
                        <h6 className={'mono'}>
                            PUMP!
                        </h6>
                        <p>
                            {
                                `Ends In ${rageModeCounter}S`
                            }
                        </p>
                    </div>

                </div>

                <div
                    className={'boost play-bottom-energy-container'}
                    onClick={() => {
                        setBoostOpen(prev => !prev);
                    }}
                >

                    <img src={rocket} />

                    <div className={'boost play-bottom-energy-text'}>
                        <h6>
                            Boost
                        </h6>
                        <p>
                            Taps/Energy
                        </p>
                    </div>

                </div>

            </div>




        </div>
    );
};

export default Main;