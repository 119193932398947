import React, {useEffect, useReducer, useRef, useState} from 'react';
import Lottie from "react-lottie";
import {element} from "prop-types";

/*import animationDataTrump1 from "../assets/animation/r_01.json";
import animationDataTrump2 from "../assets/animation/r_02.json";
import animationDataTrump3 from "../assets/animation/r_03.json";
import animationDataTrump4 from "../assets/animation/r_04.json";
import animationDataTrump5 from "../assets/animation/r_05.json";
import animationDataTrump6 from "../assets/animation/r_06.json";
import animationDataTrump7 from "../assets/animation/r_07.json";
import animationDataTrump8 from "../assets/animation/r_08.json";
import animationDataTrump9 from "../assets/animation/r_09.json";
import animationDataTrump10 from "../assets/animation/r_010.json";*/

// import trump5 from "../assets/trump_levels/trump7.png";

const TrumpLottie = ({levelChangeModal, trumpLevel, url, animPath, animPathStatic, lottieDefaultOptions}) => {



    const [staticOn, setStaticOn] = useState(true);


    const refAnim = useRef(null);

    useEffect(() => {

        let count = 0;

        const interval = setInterval(() => {
            count++;

            if (count % 2 === 1) {
                refAnim?.current?.play();
            } else {
                refAnim?.current?.stop();
            }


        }, 4000)

        return () => {
            clearInterval(interval);
        }

    }, [refAnim])



    return (
        // true ?
            <div className={`${true ? 'active' : ''} btcs-img`}>
                <Lottie
                    options={{
                        ...lottieDefaultOptions,
                        path: animPath,
                    }}
                    height={'100%'}
                    ref={refAnim}
                    // style={{opacity: staticOn ? '1' : '0'}}
                    // name={'stat'}
                />
            </div>

            /*<div className={`${true ? 'active' : ''} btcs-img`}>
                <Lottie
                    options={{
                        ...lottieDefaultOptions,
                        path: animPath,
                    }}
                    height={'100%'}
                    width={'100%'}
                    ref={refAnim}
                    isStopped={() => setStaticOn(false)}
                    name={'anim'}
                />
            </div>*/
        /*:
        <div className={`${true ? 'active' : ''} btcs-img`}>
            <Lottie
                options={{
                    ...lottieDefaultOptions,
                    animPath: animPathStatic,
                }}
                height={'100%'}
                ref={refAnim}
            />
        </div>*/
    );
};

export default TrumpLottie;