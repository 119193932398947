import hat from './assets/boosters/hat.png'
import tie from './assets/boosters/tie.png'



export const boostSchema = [

    {
        id: 1,
        name: `Multi PUMP`,
        desc: 'Boost the PUMP earned per Tap',
        desc_ru: 'Увеличить PUMP за Тап',
        img: tie,
        boostLevels: [
            {
                level: 0,
                boost: 2,
                price: 2000,
            },
            {
                level: 1,
                boost: 3,
                price: 10000,
            },
            {
                level: 2,
                boost: 4,
                price: 25000,
            },
            {
                level: 3,
                boost: 5,
                price: 50000,
            },
            {
                level: 4,
                boost: 6,
                price: 150000,
            },
            {
                level: 5,
                boost: 7,
                price: 300000,
            },
            {
                level: 6,
                boost: 8,
                price: 500000,
            },
        ],

    },

    {
        id: 2,
        name: 'Energy Limit',
        desc: 'Increase the amount of Energy',
        desc_ru: 'Увеличить количество энергии',
        img: hat,
        boostLevels: [
            {
                level: 0,
                boost: 1500,
                price: 3000,
            },
            {
                level: 1,
                boost: 2000,
                price: 12000,
            },
            {
                level: 2,
                boost: 2500,
                price: 35000,
            },
            {
                level: 3,
                boost: 3000,
                price: 85000,
            },
            {
                level: 4,
                boost: 3500,
                price: 200000,
            },
            {
                level: 5,
                boost: 4000,
                price: 500000,
            },
            {
                level: 6,
                boost: 4500,
                price: 750000,
            },
        ],
    },

];













































































































