import React, {useEffect, useState} from 'react';
import './Countersign.css';

import c0 from '../assets/cypher/flag.png';
import c1 from '../assets/cypher/bit.png';
import c2 from '../assets/cypher/cap.png';
import c3 from '../assets/cypher/tp.png';
import c4 from '../assets/cypher/face.png';

import arrow from '../assets/rewards/countersign_arrow.svg';
import axios from "axios";

import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

const countersignOptions = [
    c1, c2, c3, c4, c0
]

const countersignDummie = [
    '','','','',''
]

// tp face bit flag face    cap
// 2  3    0   4    3       1

// flag tp face flag cap
// 4 2 3 4 1

// 4
// tp bit tp cap cap
// 20211

// 6
// tp face flag cap flag
// 23414

// 25-08
// flag cap tp bit tp
// 41202

// 26-08
// tp flag flag cap bit
// 24410




let countersignCodeString = '';

const Countersign = ({
                         rewardsModal,
                         setRewardsModal,
                         rewardsStatusRequest,
                         counter,
                         setFarmUpdateCompleted,
                         available,
                         authToken,
                         urlNew,
                         waitMessage,
                         setWaitMessage,
                         successMessage,
                         setSuccessMessage,
                         setSuccessMessageText,
                         setErrorMessage,
                         setErrorMessageText,
                     }) => {

    const [u1, su1] = useState(1);



    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();



    const [c0, sc0] = useState(0);
    const [c1, sc1] = useState(1);
    const [c2, sc2] = useState(2);
    const [c3, sc3] = useState(3);
    const [c4, sc4] = useState(4);

    let countersignCode = [c0, c1, c2, c3, c4];


    const [countersignAvailable, setCountersignAvailable] = useState(available);


    const verifyCypher = () => {

        // /api/v1/cipher/current

        countersignCodeString = '';

        setWaitMessage(true);

        countersignCodeString += (c0.toString() + c1.toString() + c2.toString() + c3.toString() + c4.toString());

        axios.post(`${urlNew}/api/v1/cipher/guess`, {"code": countersignCodeString},{headers: {"X-Auth-Token": authToken.current,}})
            .then((response) => {

                setRewardsModal(false);

                rewardsStatusRequest();
                setCountersignAvailable(false);

                setWaitMessage(false);

                setFarmUpdateCompleted(true);

                counter.current += 50000;


                setTimeout(() => {
                    setFarmUpdateCompleted(false);
                }, 1800)


                setSuccessMessage(true);
            })
            .catch(error => {
                setErrorMessageText('Invalid Countersign');
                setErrorMessage(true);
                // console.log(error);
            })

    }



    return (

        <>

            <div className={`${countersignAvailable && rewardsModal ? 'active' : ''} countersign-wrapper`}>

                <div className={'countersign-container'}>

                    <div className={'countersign-container-bg'}></div>
                    <div className={'countersign-container-bg-out'}></div>

                    {
                        countersignDummie.map((option, index) =>
                            <div className={'countersign-item'}>

                                <div
                                    className={'countersign-item-arrow-container'}
                                    onTouchStart={() => {
                                        if (index === 0) {
                                            c0 === 4 ? sc0(0) : sc0(prev => prev + 1)
                                        } else if (index === 1) {
                                            c1 === 4 ? sc1(0) : sc1(prev => prev + 1)
                                        } else if (index === 2) {
                                            c2 === 4 ? sc2(0) : sc2(prev => prev + 1)
                                        } else if (index === 3) {
                                            c3 === 4 ? sc3(0) : sc3(prev => prev + 1)
                                        } else if (index === 4) {
                                            c4 === 4 ? sc4(0) : sc4(prev => prev + 1)
                                        }
                                        impactOccurred('medium');
                                    }}
                                >
                                    <img
                                        className={'countersign-item-arrow'}
                                        src={arrow}
                                    />
                                </div>

                                <div className={'countersign-item-option-container'}>
                                    <img className={'countersign-item-img'}
                                         src={countersignOptions[countersignCode[index]]}/>
                                </div>

                                <div
                                    className={'countersign-item-arrow-container'}
                                    onTouchStart={() => {
                                        if (index === 0) {
                                            c0 === 0 ? sc0(4) : sc0(prev => prev - 1)
                                        } else if (index === 1) {
                                            c1 === 0 ? sc1(4) : sc1(prev => prev - 1)
                                        } else if (index === 2) {
                                            c2 === 0 ? sc2(4) : sc2(prev => prev - 1)
                                        } else if (index === 3) {
                                            c3 === 0 ? sc3(4) : sc3(prev => prev - 1)
                                        } else if (index === 4) {
                                            c4 === 0 ? sc4(4) : sc4(prev => prev - 1)
                                        }
                                        impactOccurred('medium');
                                    }}
                                >
                                    <img
                                        className={'down countersign-item-arrow'}
                                        src={arrow}
                                    />
                                </div>

                            </div>
                        )
                    }

                </div>

                <button
                    onClick={() => verifyCypher()}
                    className={`btn primary round ${waitMessage ? 'disabled' : ''}`}
                >
                    {
                        available ?
                            'Submit'
                            :
                            'Collected'
                    }
                </button>

            </div>
        </>

    );
};

export default Countersign;