import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import './Play.css';
import './Farm.css';
import './Boost.css';
import './Rewards.css';
import './Patriots.css';
import './PlayNav.css'
import './Modal.css'
import Head, {trumpRangs} from "./Head";
import PlayBalance from "./PlayBalance";

import axios from "axios";
// let controller = new AbortController();


import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

import {farm, farmSchema, trumpologySchema} from '../FarmSchema';

/*import trump1 from '../assets/trump_levels/trump1.png';
import trump2 from '../assets/trump_levels/trump2.png';
import trump3 from '../assets/trump_levels/trump3.png';
import trump4 from '../assets/trump_levels/trump4.png';
import trump5 from '../assets/trump_levels/trump5.png';
import trump4 from '../assets/trump_levels/trump4.png';
import trump5 from '../assets/trump_levels/trump5.png';*/
// import bg from '../assets/bg.svg';
import bg from '../assets/bg.png';

import vote_gold from '../assets/vote_gold.png';

import ava_main_season from '../assets/ava_main_season.png';

import bronze_bar from '../assets/farm/bars/bronze.png';
import silver_bar from '../assets/farm/bars/silver.png';
import gold_bar from '../assets/farm/bars/gold.png';
import platinum_bar from '../assets/farm/bars/platinum.png';
import diamond_bar from '../assets/farm/bars/diamond.png';

// import squad_gold from "../assets/nav/squad_gold.svg";
// import squad from "../assets/nav/squad.svg";
import squad from "../assets/nav/squad.png";

// import rewards_gold from "../assets/nav/rewards_gold.svg";
// import rewards from "../assets/nav/rewards.svg";
import rewards from "../assets/nav/rewards.png";

// import play_gold from "../assets/nav/play_gold.svg";
// import play from "../assets/nav/play.svg";
import play from "../assets/nav/play.png";

// import boost_gold from "../assets/nav/boost_gold.svg";
// import boost from "../assets/nav/boost.svg";
import energy_img from "../assets/nav/energy.png";

// import about_gold from "../assets/nav/about_gold.svg";
// import about from "../assets/nav/about.svg";
import about from "../assets/nav/about.png";


// import blank_question from "../assets/blank_question.png";

// import rocket from "../assets/nav/rocket.svg";
import rocket from "../assets/nav/rocket.png";

import back from "../assets/back.png";

import farm_img from "../assets/nav/farm.png";
import farm_blue_img from "../assets/nav/farm_blue.png";

// import help from "../assets/help.svg";
import help from "../assets/help.png";
import share from "../assets/share.png";


import energy_drink from "../assets/boosters/energy_drink.png";
import rage_mode from "../assets/boosters/rage_mode.png";
import tie from "../assets/boosters/tie.png";
import tie_down from "../assets/boosters/tie_down.png";
import hat from '../assets/boosters/hat.png'


import gift from "../assets/patriots/gift.png";
import gift_two from "../assets/patriots/gift_two.png";


import rewards_calendar from "../assets/rewards/calendar.png";
import rewards_telegram from "../assets/rewards/telegram.png";
import rewards_x from "../assets/rewards/x.png";
import x_gold from "../assets/rewards/gold_x.png";
import rewards_patriots from "../assets/rewards/patriots.png";

import kamala_vote from "../assets/rewards/kamala_vote.png";
import trump_vote from "../assets/rewards/trump_vote.png";

import pump_organisation_img from "../assets/rewards/pump_organisation.png";
import story_img from "../assets/rewards/story.png";
import countersign_img from "../assets/rewards/countersign.png";


import flag from "../assets/flag.png";
import {boostSchema} from "../BoostSchema";
import blank from "../assets/blank.png";
// import blank_maga from "../assets/blank_maga.png";
import Welcome from "./Welcome";
import DesktopBlock from "./DesktopBlock";


import patriot1 from '../assets/pfps/patriot1.png';
import voter2 from '../assets/pfps/voter2.png';
import volunteer3 from '../assets/pfps/volunteer3.png';
import election_observer4 from '../assets/pfps/election_observer4.png';
import personal_aide5 from '../assets/pfps/personal_aide5.png';
import chief_of_staff6 from '../assets/pfps/chief_of_staff6.png';
import campaign_manager7 from '../assets/pfps/campaign_manager7.png';
import trumpump_vp8 from '../assets/pfps/trumpump_vp8.png';
import trumpump_svp9 from '../assets/pfps/trumpump_svp9.png';
import trumpump_evp10 from '../assets/pfps/trumpump_evp10.png';


import wallet from "../assets/wallet.png";
import youtube from "../assets/rewards/youtube.png";
import fb from "../assets/rewards/fb.png";
import Roadmap from "./Roadmap";
import DebtModal from "./DebtModal";
import Main from "./Main";
import PlayNav from "./PlayNav";
import DebtInvestModal from "./DebtInvestModal";
import Countersign from "./Countersign";
import FarmModal from "./FarmModal";


import trump_static11 from '../assets/trump_levels_static/trump1.json';
import trump_static22 from '../assets/trump_levels_static/trump1.json';
import trump_static33 from '../assets/trump_levels_static/trump1.json';
import trump_static44 from '../assets/trump_levels_static/trump1.json';
import trump_static55 from '../assets/trump_levels_static/trump1.json';
import trump_static66 from '../assets/trump_levels_static/trump6.png';
import trump_static77 from '../assets/trump_levels_static/trump7.png';
import trump_static88 from '../assets/trump_levels_static/trump8.png';
import trump_static99 from '../assets/trump_levels_static/trump9.png';
import trump_static100 from '../assets/trump_levels_static/trump10.png';


// PROD
const url = 'https://trumpump.app';

const animationMapping = {
    1: `${url}/animation/r_06.json`,
    11: trump_static11,
    2: `${url}/animation/r_07.json`,
    12: trump_static22,
    3: `${url}/animation/r_04.json`,
    13: trump_static33,
    4: `${url}/animation/r_03.json`,
    14: trump_static44,
    5: `${url}/animation/r_05.json`,
    15: trump_static55,
    6: `${url}/animation/r_02.json`,
    16: trump_static66,
    7: `${url}/animation/r_08.json`,
    17: trump_static77,
    8: `${url}/animation/r_01.json`,
    18: trump_static88,
    9: `${url}/animation/r_010.json`,
    19: trump_static99,
    10: `${url}/animation/r_09.json`,
    20: trump_static100,
};

// TEST
// const url = 'https://trmpmp-dev-domain.online';
const urlNew = 'https://trumpump.app';
// const urlNew = 'https://trmpmp-dev-domain.ru';

// LOCAL
// const url = 'http://localhost:8800'


export const tg = window.Telegram.WebApp;

// PROD
export const client_id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

// TEST
// export const client_id = 5616427503;
// export const client_id = 692428510;

export const client_username = window?.Telegram?.WebApp?.initDataUnsafe?.user?.username;
export const client_firstname = window?.Telegram?.WebApp?.initDataUnsafe?.user?.first_name;
export const client_lastname = window?.Telegram?.WebApp?.initDataUnsafe?.user?.last_name;
export const client_start_param = window?.Telegram?.WebApp?.initDataUnsafe?.start_param;
export const client_is_premium = window?.Telegram?.WebApp?.initDataUnsafe?.user?.is_premium === true ? 1 : 0;

// export let client_language_code = window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code == 'ru' ? 'ru' : 'en';
export const client_language_code = 'en';


window?.Telegram?.WebApp?.setHeaderColor('#000000');
window?.Telegram?.WebApp?.setBackgroundColor('#000000');


// PROD
const referralShareLink = `https://t.me/share/url?url=https://t.me/trumpumpbot/tpwa?startapp=${client_id}`;
const referralCopyLink = `https://t.me/trumpumpbot/tpwa?startapp=${client_id}`;

// TEST
// const referralShareLink = `https://t.me/share/url?url=https://t.me/trumpumptestbot/tptwa?startapp=${client_id}`;
// const referralCopyLink = `https://t.me/trumpumptestbot/tptwa?startapp=${client_id}`;


// Helpers

export const convertNumberToShortString = (num) => {

    if (num < 1000) {
        return num?.toLocaleString(undefined, {maximumFractionDigits: 2})
    } else if (num >= 1000 && num < 1000000) {
        return (num / 1000)?.toLocaleString(undefined, {maximumFractionDigits: 2}) + 'K'
    } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000)?.toLocaleString(undefined, {maximumFractionDigits: 2}) + 'M'
    } else if (num >= 1000000000) {
        return (num / 1000000000)?.toLocaleString(undefined, {maximumFractionDigits: 2}) + 'B'
    }

};

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    return hours + 'H' + ' ' + minutes + 'M';
}

export function sToTime(duration) {
    var seconds = Math.floor((duration) % 60),
        minutes = Math.floor((duration / (60)) % 60),
        hours = Math.floor((duration / (60 * 60)) % 24);

    return hours + 'H' + ' ' + minutes + 'M';
}


let friendsUserArray = [];
let friendsUserArrayNotTaken = [];

let friendsUserArrayEarn = 0;
let friendsUserArrayFarm = 0;
let friendsUserArrayFarmCalc = 0;


const Play = (callback, deps) => {


    console.log('hello', Date.now());


    const [friendsUserArrayDummie, setFriendsUserArrayDummie] = useState(false);

    /*const friendsUserArrayNotTakenPrice = useRef(0);
    const friendsUserArrayEarn = useRef(0);
    const friendsUserArrayFarm = useRef(0);
    const friendsUserArrayFarmCalc = useRef(0);*/


    // Prevent Collapse

    const voteBtnRef = useRef(null);

    /*function testhandler(object){
        if (!this.isExpanded){
            this.expand()
        }
    }*/

    // Telegram.WebApp.onEvent('viewportChanged', testhandler);


    const overflow = 100;
    document.body.style.overflowY = 'hidden';
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + "px";
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);

    let ts;

    const onTouchStart = (e) => {
        ts = e.touches[0].clientY
    };

    const onTouchMove = (e) => {
        if (voteBtnRef) {
            const scroll = voteBtnRef.scrollTop;
            const te = e.changedTouches[0].clientY;
            if (scroll <= 0 && ts > te) {
                e.preventDefault()
            }
        } else {
            e.preventDefault()
        }
    };
    document.documentElement.addEventListener('touchstart', onTouchStart, {passive: false});
    document.documentElement.addEventListener('touchmove', onTouchMove, {passive: false});


    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();
    const milliseconds = now.getUTCMilliseconds();


    // US Debt

    const [debtModal, setDebtModal] = useState(false);

    const [debtInvestModal, setDebtInvestModal] = useState(false);


    const launchRequestOptions = {
        method: 'POST',
        url: `${url}/rest/getUserByUsername`,
        params: {
            tg_id: 1
            /*username: client_username,
            firstname: client_firstname,
            lastname: client_lastname,*/
        },
        headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip,deflate,compress"
        },
    };


    // Loader and Device Detection


    const [desktopBlock, setDesktopBlock] = useState(false);

    const [userOs, setUserOs] = useState('');

    useEffect(() => {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = matchMedia?.("(pointer:coarse)");
            if (mQ?.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                const UA = navigator.userAgent;
                hasTouchScreen =
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }


        if (navigator.userAgent.indexOf("Win") != -1) {
            setUserOs('Windows OS')
        }
        ;
        if (navigator.userAgent.indexOf("Mac") != -1) {
            setUserOs('Macintosh')
        }
        ;
        if (navigator.userAgent.indexOf("Linux") != -1) {
            setUserOs('Linux OS')
        }
        ;
        if (navigator.userAgent.indexOf("Android") != -1) {
            setUserOs('Android OS')
        }
        ;
        if (navigator.userAgent.indexOf("like Mac") != -1) {
            setUserOs('iOS')
        }
        ;

        setDesktopBlock(!hasTouchScreen)

    }, []);

    const [welcomeVisible, setWelcomeVisible] = useState(true);

    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
            if (!desktopBlock) {
                setSelectedItem(4);
                setTimeout(() => {
                    setSelectedItem(3);
                    setWelcomeVisible(false)
                }, 4000);
            }
            // do something else
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const removeLoader = () => {
        setWelcomeVisible(false);
    };


    const [dataUser, setDataUser] = useState({});

    const trumpLevel = useRef(null);
    const [trumpImg, setTrumpImg] = useState(0);

    const [dailyRewardLevel, setDailyRewardLevel] = useState(0);
    const [dailyRewardDate, setDailyRewardDate] = useState(0);

    const [voteRewardLevel, setVoteRewardLevel] = useState(0);
    const [voteRewardDate, setVoteRewardDate] = useState(0);

    const [voteRewardModal, setVoteRewardModal] = useState(false);

    const [telegramRewardLevel, setTelegramRewardLevel] = useState(0);
    const [xRewardLevel, setXRewardLevel] = useState(0);

    const [patriotsRewardArray, setPatriotsRewardArray] = useState([]);
    const [patriotsRewardLevel, setPatriotsRewardLevel] = useState(0);

    const [rewardsModal, setRewardsModal] = useState(false);
    const [rewardsModalItem, setRewardsModalItem] = useState(false);

    const [earnedOffline, setEarnedOffline] = useState(0);
    const [earnedOfflineModal, setEarnedOfflineModal] = useState(false);

    let absencePeriodInSec = 0;

    const energy = useRef(0);
    const [energyMax, setEnergyMax] = useState(3);
    const [energyStep, setEnergyStep] = useState(3000);

    const counter = useRef(0);
    const counterTemp = useRef(0);
    const [counterState, setCounterState] = useState(0);

    const [tokensPerClick, setTokensPerClick] = useState(1);
    const [tokensPerHour, setTokensPerHour] = useState(50);

    const [boostOneLevel, setBoostOneLevel] = useState(0);
    const [boostTwoLevel, setBoostTwoLevel] = useState(0);
    const [boostThreeLevel, setBoostThreeLevel] = useState(Date.now());
    const [boostFourLevel, setBoostFourLevel] = useState(Date.now());

    const [farmUserArray, setFarmUserArray] = useState([]);
    const [farmUserArrayUnfiltered, setFarmUserArrayUnfiltered] = useState([]);

    // const [friendsUserArray, setFriendsUserArray] = useState([]);


    // const [friendsUserArrayFarmCalc, setFriendsUserArrayFarmCalc] = useState(0);
    const [earnedOfflineWithFriends, setEarnedOfflineWithFriends] = useState(0);
    // const [friendsUserArrayNotTaken, setFriendsUserArrayNotTaken] = useState([]);


    const [dataLoaded, setDataLoaded] = useState(false);


    /*const updateData = (response) => {

        if (response) {

            setDataUser(response.data);

            counter.current = response.data.current_balance;

            absencePeriodInSec = ((Date.now() - response.data.last_update) / 1000 < 18000 ? ((Date.now() - response.data.last_update) / 1000) : 18000);

            if (absencePeriodInSec > 30 && +response.data.tokens_per_hour > 50) {
                setEarnedOffline((absencePeriodInSec * (response.data.tokens_per_hour / 3600))?.toFixed(0));
                setEarnedOfflineModal(true)
            } else {
                counter.current = response.data.current_balance;
            }

            /!*setEnergyMax(response.data.max_energy);
            let energyFull = response.data.last_energy + (absencePeriodInSec * 3);
            if (energyFull > response.data.max_energy || energyFull < response.data.last_energy) {
                energy.current = response.data.max_energy
            } else {
                energy.current = energyFull;
            }*!/

            if (response.data.trump_level === 1) {
                setTrumpImg(trump1);
            }
            if (response.data.trump_level === 2) {
                setTrumpImg(trump2);
            }
            if (response.data.trump_level === 3) {
                setTrumpImg(trump3);
            }
            if (response.data.trump_level === 4) {
                setTrumpImg(trump4);
            }
            if (response.data.trump_level === 5) {
                setTrumpImg(trump5);
            }

            setTokensPerClick(response.data.tokens_per_click);
            setTokensPerHour(+response.data.tokens_per_hour);

            setBoostOneLevel(+response.data.boost_one_level);
            setBoostTwoLevel(+response.data.boost_two_level);
            setBoostThreeLevel(+response.data.boost_three_level);
            setBoostFourLevel(+response.data.boost_four_level);

            trumpLevel.current = (+response.data.trump_level);

            // alert(trumpLevel.current);

            /!*if (response.data.trump_level > 1 && response.data.trump_level < 10) {

            } else {
                setTrumpLevel(4)
            }*!/

            setDailyRewardLevel((Date.now() - response.data.daily_reward_date) > (3600000 * 48) ? 0 : response.data.daily_reward_level);
            setDailyRewardDate((Date.now() - response.data.daily_reward_date) > (3600000 * 48) ? (Date.now() - (3600000 * 24)) : response.data.daily_reward_date);

            setVoteRewardLevel((Date.now() - response.data.vote_reward_date) > (3600000 * 24) ? 0 : response.data.vote_reward_level);
            setVoteRewardDate(response.data.vote_reward_date);

            setTelegramRewardLevel(response.data.telegram_reward_level);
            setXRewardLevel(response.data.x_reward_level);
            setPatriotsRewardLevel(response.data.patriots_reward_level);

            try {
                // setPatriotsRewardArray((response.data.friends)?.filter(patriot => (Date.now() - patriot.time_stamp) < (3600000 * 24)));
            } catch (e) {
                console.log(e)
            }

            setFarmUserArray(response.data.farm);


            setEarnedOfflineWithFriends(earnedOffline + friendsUserArrayFarmCalc);

            impactOccurred('medium');

            setFarmUpdateLoading(false);
            setBoostUpdateLoading(false);

            setDataLoaded(true)

        }

    };*/





    const [authStatus, setAuthStatus] = useState(0);
    const [authData, setAuthData] = useState({}); // id name token
    const authToken = useRef(null); // token

    const [welcomeBackLast, setWelcomeBackLast] = useState(0);
    const [welcomeBackAmount, setWelcomeBackAmount] = useState(0);

    const authRequest = () => {

        axios.post(`${urlNew}/api/v1/auth`, {
            tg_web_app_data: window?.Telegram?.WebApp?.initData,
            // tg_web_app_data: "query_id=AAHc9FIMAAAAANz0UgwrZVgh&user=%7B%22id%22%3A206763228%2C%22first_name%22%3A%22Sergey%22%2C%22last_name%22%3A%22Shcherbina%22%2C%22username%22%3A%22heeepri%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722432163&hash=b33c581ce50f7b883fe846396edd1e99d147158df45c013470e297f10ff92734",
        })
            .then(response => {

                // setWelcomeBackLast(axios.post(`${urlNew}/api/v1/state/sync`, {}, {headers: {"X-Auth-Token": token,}}).coins);

                axios.post(`${urlNew}/api/v1/state/sync`, {},{headers: {"X-Auth-Token": response.data.token,}})
                    .then((response_two) => {
                        setWelcomeBackAmount(response_two.data.diff_coins);

                        if ((response_two.data.diff_coins) > 2) {
                            setEarnedOfflineModal(true);
                        }

                        // alert(response_two.data.coins - response_one.data.state.coins);
                    });


                setAuthStatus(response.status);
                setAuthData(response.data);
                authToken.current = response.data.token;

            })
            .catch(error => {
                // alert(error);
                setErrorMessageText('Authentication Failed');
                setErrorMessage(true);
            });
    };


    const [stateStatus, setStateStatus] = useState(0);
    const [stateData, setStateData] = useState(0); // id name token


    const [patriotsFarm, setPatriotsFarm] = useState(0);
    const [patriotsReward, setPatriotsReward] = useState(0);
    const [patriotsCount, setPatriotsCount] = useState(0);

    const [treasuryInvestment, setTreasuryInvestment] = useState(0);

    const [boostUsage, setBoostUsage] = useState([]);


    const updateGameData = (response) => {

        setStateStatus(response.status);
        setStateData(response.data);

        counter.current = response.data.state.coins;
        energy.current = response.data.state.energy;

        setTokensPerClick(response.data.progress.coins_per_tap);
        setTokensPerHour(response.data.progress.income);
        trumpLevel.current = response.data.progress.level;
        setEnergyMax(response.data.progress.max_energy);

        setFarmUserArrayUnfiltered(response.data.progress.upgrades || []);

        setTreasuryInvestment(response.data.state.coins_to_treasury);

        setPatriotsFarm(response.data.progress.referral_income);
        setPatriotsReward(response.data.state.coins_from_referrals);
        setPatriotsCount(response.data.progress.referral_count || 0);


        setBoostUsage(response.data.progress.boost_usage);


    }


    const stateRequest = () => {

        // alert(window?.Telegram?.WebApp?.initData);

        axios.get(`${urlNew}/api/v1/game`, {
            headers: {
                "X-Auth-Token": authData.token,
            }
        })
            .then(response => {

                updateGameData(response);

            })
            .catch(error => {
                // alert(error);
                setErrorMessageText('Authentication Failed');
                setErrorMessage(true);
            });
    };

    useEffect(() => {
        if (farmUserArrayUnfiltered === undefined) {
            return
        }
        let farmUserArrayUnfilteredd = {
            12312: 3,
            12: 5,
            1: 7,
            203: 6,
            205: 10,
        }

        let userArrayNew = [];


        for (let i = 0; i < 580; i++) {

            let sum = 0;

            let item = farm.find(item => item.id === i);

            userArrayNew.push({
                id: i,
                level: farmUserArrayUnfiltered[i] || 0,
                total_farm: item?.farmLevels[farmUserArrayUnfiltered[i]]?.total_farm
            });

        }

        setFarmUserArray(userArrayNew);
        // console.log(userArrayNew);

        setBoostOneLevel(userArrayNew[1].level);
        setBoostTwoLevel(userArrayNew[2].level);

    }, [farmUserArrayUnfiltered])



    const [farmSchemaData, setFarmSchemaData] = useState(0);

    const farmSchemaRequest = () => {

        axios.get(`${urlNew}/api/v1/progress/upgrades`, {
            headers: {
                "X-Auth-Token": authData.token,
            }
        })
            .then(response => {

                setFarmSchemaData(response.data);

            })
            .catch(error => {
                alert(error);
                setErrorMessageText('Farm Schema Request Failed');
                setErrorMessage(true);
            });
    };

    const [rewardsStatusData, setRewardsStatusData] = useState([]);

    const rewardsStatusRequest = () => {

        axios.get(`${urlNew}/api/v1/state/tasks/status`, {
            headers: {
                "X-Auth-Token": authData.token,
            }
        })
            .then(response => {

                setRewardsStatusData(response.data);

                setDailyRewardLevel(response.data[1].available ? 1 : 0);
                // setVoteRewardLevel(response.data[1].available ? 1 : response.data[1].available ? 1 : 0);

                console.log(response.data);

            })
            .catch(error => {
                // alert(error);
                setErrorMessageText('Task Status Request Failed');
                setErrorMessage(true);
            });
    };


    const [reloadHandle, setReloadHandle] = useState(1);

    useEffect(() => {
        if (authStatus == 200) {
            stateRequest();
            farmSchemaRequest();
            rewardsStatusRequest();
            getTempBoostRequest();
            patriotsListRequest();
        }
    }, [authStatus, reloadHandle])





    useEffect(() => {

        authRequest();

        // loadData();

        // loadPatriots();

        // tg.ready();
        tg.enableClosingConfirmation();
        tg.expand();

    }, []);






    const [patriotsLoading, setPatriotsLoading] = useState(false)
    const [patriotsFail, setPatriotsFail] = useState(false)
    const [patriotsLoaded, setPatriotsLoaded] = useState(false)
    const [patriotsLoadingBlock, setPatriotsLoadingBlock] = useState(false)
    const [patriotsLoadingBlockCounter, setPatriotsLoadingBlockCounter] = useState(60)

    // const [patriotsCount, setPatriotsCount] = useState(0)
    // const [patriotsFarm, setPatriotsFarm] = useState(0)
    const [patriotsFarmCalc, setPatriotsFarmCalc] = useState(0);
    // const [patriotsReward, setPatriotsReward] = useState(0)
    const [patriotsLastArray, setPatriotsLastArray] = useState([])
    const [patriotsRewardNotTaken, setPatriotsRewardNotTaken] = useState(0)
    const [patriotsRewardNotTakenCount, setPatriotsRewardNotTakenCount] = useState(0)

    const loadPatriots = () => {

        setPatriotsLoading(true)

        axios.get(`${url}/rest/loadPatriots`, {
            params: {
                tg_id: client_id,
            }
        })
            .then(response => {
                setPatriotsFail(false);

                setPatriotsCount(response.data[0].patriots_count);
                setPatriotsFarm(response.data[0].patriots_farm);
                setPatriotsFarmCalc(absencePeriodInSec * (response.data[0].patriots_farm / 3600));
                setPatriotsReward(response.data[0].patriots_reward);
                setPatriotsLastArray(response.data[0].patriots_last_array);

                setPatriotsRewardNotTaken(response.data[1]);
                setPatriotsRewardNotTakenCount(response.data[2]);

                setPatriotsLoading(false);
                setPatriotsLoaded(true);

            })
            .catch(error => {
                setPatriotsLoading(false);
                setPatriotsLoaded(false);
                setPatriotsFail(true);
                alert(error);
                console.log(error);
                setErrorMessageText('Failed to load Patriots');
                setErrorMessage(true);
            });
    };


    const onClose = () => {
        sendCheckPointRequest();
        tg.close();
    };


    /*useEffect(() => {

        if (isNaN(counter.current) || isNaN(energy.current) || isNaN(tokensPerHour) || isNaN(tokensPerClick)) {
            location.reload()
        }

    }, [dataUser, counter.current, energy.current, tokensPerClick, tokensPerClick]);*/


    const checkPointRequestOptions = {
        method: 'POST',
        // url: `${url}/rest/updateUserByCheckPoint?username=${client_username}&current_balance=${counter}`,
        url: `${url}/rest/updateUserByCheckPoint`,
        headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip,deflate,compress"
        },
        params: {
            id: client_id,
            current_balance: counter.current,
        }
    };

    const [checkPointLoading, setCheckPointLoading] = useState(false);


    const sendCheckPointRequest = (clearFriends = false, newTrumpLevel = 0) => {

        /*setCheckPointLoading(true);

        axios.get(`${url}/rest/updateUserByCheckPoint`, {
            params: {
                tg_id: client_id,
                current_balance_temp: counterTemp.current,
                last_energy: energy.current,
            },
            // signal: newTrumpLevel === 0 ? controller.signal : ''
        })
            .then((response) => {

                counter.current = (response.data.counter_current);
                counterTemp.current = 0;

                setCheckPointLoading(false);
            })
            .catch(error => {
                console.log(error);
            })*/

    };


    const [intervalCounter, setIntervalCounter] = useState(0);
    const intervalCounterCheckPoint = useRef(0);

    const [levelsModal, setLevelsModal] = useState(false);
    const [levelChangeModal, setLevelChangeModal] = useState(false);

    const [trumpLevelState, setTrumpLevelState] = useState(1);

    useEffect(() => {

        const interval = setInterval(() => {


            counter.current = counter.current + (Number(tokensPerHour) / 3600);
            // intervalCounter.current = intervalCounter.current + 1;
            setIntervalCounter(prev => prev + 1);


            /*if (intervalCounter.current % 2 === 1) {
                client_language_code = 'ru'
            } else {
                client_language_code = 'en'
            }*/


            // Level Change Client

            if ((counter.current >= trumpLevelsSchema[trumpLevel.current]) && trumpLevel.current >= 1) {

                trumpLevel.current += 1;
                setTrumpLevelState(trumpLevel.current);

                setLevelChangeModal(true);

            } else {

                if (energy.current > (energyMax - 3)) {
                    energy.current = energy.current + (energyMax - energy.current)
                } else if (energy.current < energyMax) {
                    energy.current = energy.current + 3
                }

            }

        }, 1000);

        return () => {
            clearInterval(interval);
        }

    }, [stateData, patriotsLoaded]);


    // Checkpoint Interval


    useEffect(() => {

        const interval = setInterval(() => {

            if (true) {
                sendCheckPointRequest(false, 0)
            }

        }, 10000);

        return () => {
            clearInterval(interval)
        }

    }, [dataUser]);


    // Checkpoint Timeout


    /*const checkpointTimeout = setTimeout(() => {
        sendCheckPointRequest(false, 0)
    }, 5000);*/

    useEffect(() => {

        /*clearTimeout(checkpointTimeout);

        checkpointTimeout()*/

    }, []);


    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();


    // Farm =====================


    const [farmNav, setFarmNav] = useState(2);

    let farmFiltered = farm.filter(farm => farm.type === 1);

    const changeFarmNav = (a) => {

        setFarmNav(a);

        farmFiltered = farm.filter(farm => farm.type === a)

    };


    const [farmModal, setFarmModal] = useState(false);
    const [farmModalItem, setFarmModalItem] = useState(farm[0]);

    const [farmUpdateLoading, setFarmUpdateLoading] = useState(false);
    const [farmUpdateCompleted, setFarmUpdateCompleted] = useState(false);


    useEffect(() => {
        if (!farmModal) {

            setTimeout(() => {

                if (!farmModal) {
                    setFarmModalItem(
                        {
                            id: 100,
                            name: '',
                            description: '',
                            img: blank,
                            type: 0,
                            class: 0,
                            farm: 0,
                            price: 0,
                            farm_koef: 1,
                            price_koef: 1,
                            farmLevels: [
                                {
                                    level: 0,
                                    price: 0,
                                    farm: 0,
                                    total_price: 0,
                                    total_farm: 0,
                                }
                            ],
                            activation_trigger_type: 0,
                            activation_trigger_goal: 0,
                            activation_trigger_amount: 0,
                        }
                    )
                } else {
                    setFarmModal(false)
                }

            }, 500)
        }
    }, [farmModal]);


    const updateFarmRequest = () => {

        setFarmUpdateLoading(true);

        setWaitMessage(true);

        axios.post(`${urlNew}/api/v1/game/upgrades/buy`, {"upgrade_id": farmModalItem.id}, {
            headers: {
                "X-Auth-Token": authToken.current,
            },
        })
            .then(response => {

                setFarmModal(false);

                setFlyingItemsImg(vote_gold);


                updateGameData(response);


                setFarmUpdateCompleted(true);

                setWaitMessage(false);

                setSuccessMessage(true);

                setTimeout(() => {
                    setFarmUpdateCompleted(false);
                    setFarmUpdateLoading(false);
                }, flyingItemsAnimTime);
            })
            .catch(error => {
                // alert(error);
                setErrorMessageText('Farm Update Failed');
                setErrorMessage(true);
            });

    };











    // Boosters =====================





    const [boostOpen, setBoostOpen] = useState(false);

    const [boostModal, setBoostModal] = useState(false);
    const [boostModalItem, setBoostModalItem] = useState(boostSchema[0]);

    const [boostUpdateLoading, setBoostUpdateLoading] = useState(false);
    const [boostUpdateCompleted, setBoostUpdateCompleted] = useState(false);

    const [energyBoostUpdateCompleted, setEnergyUpdateCompleted] = useState(false);


    const [rageMode, setRageMode] = useState(false);
    const [rageModeCounter, setRageModeCounter] = useState(30);

    useEffect(() => {

        const interval = setInterval(() => {

            if (rageMode) {
                setBoostFourLevel(Date.now());
                setRageModeCounter(prev => prev - 1);
                if (rageModeCounter === 0) {
                    setRageMode(false);
                    setRageModeCounter(30)
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval)
        }

    }, [rageMode]);


    const updateBoostRequest = (id, price) => {


        setBoostUpdateLoading(true);

        setWaitMessage(true);

        axios.post(`${urlNew}/api/v1/game/upgrades/buy`, {"upgrade_id": boostModalItem?.id}, {
            headers: {
                "X-Auth-Token": authToken.current,
            },
        })
            .then((response) => {

                if (response.data !== "101") {

                    setBoostModal(false);

                    if (boostModalItem?.id === 1) {
                        setFlyingItemsImg(tie);
                    } else if (boostModalItem?.id === 2) {
                        setFlyingItemsImg(hat);
                    }

                    updateGameData(response);

                    setBoostUpdateCompleted(true);

                    setWaitMessage(false);

                    setSuccessMessage(true);

                    setTimeout(() => {
                        setBoostUpdateCompleted(false)
                        setBoostUpdateLoading(false);
                    }, flyingItemsAnimTime);

                } else {
                    console.log(error);
                    setErrorMessage(true);
                }

            })
            .catch(error => {
                // alert(error);
                console.log(error);
                setErrorMessage(true);
            });

    };






    const [tempBoostsArray, setTempBoostsArray] = useState([]);


    const getTempBoostRequest = () => {

        axios.get(`${urlNew}/api/v1/progress/boosts`, {
            headers: {
                "X-Auth-Token": authData.token,
            },
        })
            .then((response) => {

                setTempBoostsArray(response.data);

                console.log(tempBoostsArray);

            })
            .catch(error => {
                alert(error);
                console.log(error);
                setErrorMessage(true);
            });

    };




    const updateTempBoostRequest = (id) => {

        setBoostUpdateLoading(true);

        setWaitMessage(true);

        axios.post(`${urlNew}/api/v1/game/boosts/activate`, {"boost_id": id}, {
            headers: {
                "X-Auth-Token": authToken.current,
            },
        })
            .then((response) => {

                if (id === 1) {
                    setFlyingItemsImg(rage_mode);
                } else if (id === 2) {
                    setFlyingItemsImg(energy_drink);
                }

                setEnergyUpdateCompleted(true);

                setBoostUpdateLoading(false);

                setWaitMessage(false);

                setSuccessMessage(true);

                setReloadHandle(prev => prev + 1);

                if (id === 1) {
                    setRageMode(true);

                    setBoostOpen(false);

                    /*const tpk_temp = tokensPerClick;
                    setTokensPerClick(tokensPerClick * 3);*/

                    setTimeout(() => {
                        // setTokensPerClick(tpk_temp);
                        setRageMode(false);
                    }, 30000)
                }

                if (id === 2) {

                    energy.current = energyMax;

                }

                setTimeout(() => {
                    setBoostUpdateCompleted(false)
                }, flyingItemsAnimTime);

            })
            .catch(error => {
                // alert(error);
                console.log(error);
                setErrorMessage(true);
            });

    };
















    // Trump Level =====================


    const userLevels = [

        {
            label: 'Patriot',
            img: patriot1,
        },

        {
            label: 'Voter',
            img: voter2,
        },

        {
            label: 'Volunteer',
            img: volunteer3,
        },

        {
            label: 'Election Observer',
            img: election_observer4,
        },

        {
            label: 'Personal Aide',
            img: personal_aide5,
        },

        {
            label: 'Chief Of Staff',
            img: chief_of_staff6,
        },

        {
            label: 'Campaign Manager',
            img: campaign_manager7,
        },

        {
            label: 'Trumpump VP',
            img: trumpump_vp8,
        },

        {
            label: 'Trumpump SVP',
            img: trumpump_svp9,
        },

        {
            label: 'Trumpump EVP',
            img: trumpump_evp10,
        },

    ];


    const trumpLevelsSchema = [
        0, // 1
        25000, // 2
        50000, // 3
        100000, // 4
        1000000, // 5
        5000000, // 6
        10000000, // 7
        50000000, // 8
        100000000, // 9
        500000000, // 10
        1000000000000000000000000, // 11
    ];




    const trumpLevelChangeRequest = () => {


        axios.get(`${url}/rest/updateUserTrumpLevel`, {
            params: {
                tg_id: client_id,
                current_balance: counter.current,
                last_energy: energy.current,
            },
        })
            .then((response) => {
                loadData();
                setLevelChangeModal(true);
            })
            .catch(error => {
                console.log(error);
                // alert(error);
            })
    };


    // Rewards Schema =====================

    let RewardsSchema = [

        {
            id: 44,
            type: 3,
            name: 'PUMP Organization',
            desc: 'Coming Soon...',
            image: pump_organisation_img,
            level: 0,
            available: true,
            link: '',
        },

        {
            id: 4,
            type: 3,
            name: 'PUMP Story',
            desc: 'PUMP telegram story and get a reward',
            image: story_img,
            level: 0,
            available: rewardsStatusData?.find(task => task.id === 13)?.available,
            link: '',
        },

        {
            id: 444,
            type: 3,
            name: 'PUMP Tweet',
            desc: 'PUMP tweet and get a reward',
            image: x_gold,
            level: 0,
            available: rewardsStatusData?.find(task => task.id === 18)?.available,
            link: `https://twitter.com/intent/tweet?url=https%3A%2F%2Ft.me%2Ftrumpumpbot%2Ftpwa%3Fstartapp%3D${client_id}&text=Join+%40trumpump_app+squad+and+start+playing+%23TrumPump+today%21+Big+airdrop+coming+soon%21`,
        },

        {
            id: 3,
            type: 1,
            name: 'Daily Countersign',
            desc: 'Enter the secret code and get a reward',
            image: countersign_img,
            level: 0,
            available: rewardsStatusData?.find(task => task.id === 999)?.available,
            link: '',
        },

        {
            id: 1,
            type: 1,
            name: 'Daily Reward',
            desc: 'Stay in the game and claim dividends!',
            image: rewards_calendar,
            level: rewardsStatusData?.find(task => task.id === 1)?.id,
            available: rewardsStatusData?.find(task => task.id === 1)?.available,
            step: rewardsStatusData?.find(task => task.id === 1)?.step,
            link: '',
        },

        {
            id: 5,
            type: 1,
            name: 'Cast a Vote!',
            desc: 'Cast a vote for your candidate',
            image: trump_vote,
            image_two: kamala_vote,
            level: rewardsStatusData?.find(task => task.id === 2)?.id,
            available: rewardsStatusData?.find(task => task.id === 2)?.available,
            link: '',
        },

        {
            id: 11,
            type: 2,
            name: 'Join Telegram Channel',
            desc: 'Join Trumpump\'s Telegram channel',
            image: rewards_telegram,
            level: rewardsStatusData?.find(task => task.id === 10)?.id,
            available: rewardsStatusData?.find(task => task.id === 10)?.available,
            link: 'https://t.me/trumpump_app',
        },

        {
            id: 12,
            type: 2,
            name: 'Follow Our X',
            desc: 'Follow Trumpump\'s X account',
            image: rewards_x,
            level: rewardsStatusData?.find(task => task.id === 11)?.id,
            available: rewardsStatusData?.find(task => task.id === 11)?.available,
            link: 'https://x.com/trumpump_app',
        },

        {
            id: 15,
            type: 2,
            name: 'Patriot Awards',
            desc: 'Invite Patriots and get a reward',
            image: patriot1,
            amount: 5,
            price: 50000,
            visible: patriotsCount >= 0 || rewardsStatusData?.find(task => task.id === 12)?.available,
            level: 0,
            step: 0,
            available: false,
            link: `https://t.me/share/url?url=https://t.me/trumpumpbot/tpwa?startapp=${client_id}`,
        }

    ];





    /*useEffect(() => {

        for (let i = 0; i < patriotRewardSchema.length; i++) {

            setPatriotRewardLevelAvailable(patriotRewardSchema.find((i) => i.available === true));

        }

    }, [rewardsStatusData]);*/

    let patriotRewardSchema = [

        /*{
            id: 1,
            image: patriot1,
            amount: 0,
            price: 0,
            available: rewardsStatusData?.find(task => task.id === 12)?.available,
        },*/

        {
            id: 12,
            image: patriot1,
            amount: 5,
            price: 50000,
            available: rewardsStatusData?.find(task => task.id === 12)?.available,
            step: rewardsStatusData?.find(task => task.id === 12)?.step,
        },

        {
            id: 14,
            image: volunteer3,
            amount: 10,
            price: 125000,
            available: rewardsStatusData?.find(task => task.id === 14)?.available,
            step: rewardsStatusData?.find(task => task.id === 14)?.step,
        },

        {
            id: 15,
            image: chief_of_staff6,
            amount: 50,
            price: 500000,
            available: rewardsStatusData?.find(task => task.id === 15)?.available,
            step: rewardsStatusData?.find(task => task.id === 15)?.step,
        },

        {
            id: 16,
            image: trumpump_vp8,
            amount: 100,
            price: 1000000,
            available: rewardsStatusData?.find(task => task.id === 16)?.available,
            step: rewardsStatusData?.find(task => task.id === 16)?.step,
        },

        {
            id: 17,
            image: trumpump_evp10,
            amount: 500,
            price: 5000000,
            available: rewardsStatusData?.find(task => task.id === 17)?.available,
            step: rewardsStatusData?.find(task => task.id === 17)?.step,
        },

    ]

    const [patriotRewardLevelCollected, setPatriotRewardLevelCollected] = useState(0);
    const patriotRewardLevelAvailable = patriotRewardSchema?.find((i) => i.amount <= patriotsCount && i.step !== 1 && i.step !== 2)?.id || 0;




    let dailyRewardsSchema = [
        500,
        1000,
        3000,
        5000,
        10000,
        17500,
        25000,
        50000,
        100000,
        200000
    ];



    const [voteRewardCoins, setVoteRewardCoins] = useState(10000);

    const rewardChangeRequest = (id, option) => {

        setFarmUpdateLoading(true);

        setWaitMessage(true);

        axios.post(`${urlNew}/api/v1/state/tasks/complete`, {"task_id": id, "option_id": option}, {
            headers: {
                "X-Auth-Token": authToken.current,
            },
        })
            .then(response => {

                if (id === 2) {
                    setVoteRewardCoins(response.data.coins);
                    setTimeout(() => {
                        setVoteRewardModal(true);
                    }, 750)

                }

                if (id !== 13 && id !== 18) {
                    setRewardsModal(false);

                    setFlyingItemsImg(vote_gold);
                }


                counter.current = counter.current + response.data.coins;

                rewardsStatusRequest();


                if (id !== 13 && id !== 18) {
                    setFarmUpdateCompleted(true);
                }

                setWaitMessage(false);

                setSuccessMessage(true);


                setTimeout(() => {
                    if (id !== 13 && id !== 18) {
                        setFarmUpdateCompleted(false);
                    }
                    setFarmUpdateLoading(false);
                }, flyingItemsAnimTime);

            })
            .catch(error => {

                // alert(error.response.status);

                if (rewardsModalItem?.id !== 4 && rewardsModalItem?.id !== 444 && rewardsModalItem?.id !== 11 && rewardsModalItem?.id !== 12) {
                    if (rewardsModalItem?.id === 11 && error.response.status === 400) {
                        setErrorMessageText('Not Subscribed');
                    } else {
                        setErrorMessageText('Task Update Failed');
                    }
                    setErrorMessage(true);
                }
            });

    };


    const voteRewardChangeRequest = (vote) => {

        // setVoteRewardLevel(-1);


        axios.get(`${url}/rest/updateUserVoteReward`, {
            params: {
                tg_id: client_id,
                current_balance: counter.current + (vote === 1 ? 10000 : (counter.current <= 5000 ? -counter.current : -5000)),
                last_energy: energy.current,
                vote_reward_level: vote,
            },
        })
            .then((response) => {
                setFarmUpdateCompleted(true);
                setRewardsModal(false);
                setFlyingItemsImg(vote === 1 ? tie : tie_down);
                setVoteRewardModal(true);
                // setVoteRewardLevel(vote);
                counter.current = counter.current + (vote === 1 ? 10000 : (counter.current <= 5000 ? -counter.current : -5000));
                setVoteRewardDate(Date.now());
                setTimeout(() => {
                    setFarmUpdateCompleted(false);
                }, flyingItemsAnimTime)
            })
            .catch(error => {
                console.log(error);
            })
    };


    const telegramRewardVerifyRequest = () => {

        // controller.abort();

        setWaitMessage(true);

        axios.get(`${url}/rest/verifyUserTelegramReward`, {
            params: {
                tg_id: client_id,
            },
        })
            .then((response) => {

                if (response.data === "member" || response.data === "administrator") {
                    telegramRewardChangeRequest()
                } else {
                    setErrorMessageText('Subscribe to the channel');
                    setErrorMessage(true);
                }

            })
            .catch(error => {
                console.log(error);
            })
    };

    const telegramRewardChangeRequest = () => {

        // controller.abort();

        axios.get(`${url}/rest/updateUserTelegramReward`, {
            params: {
                tg_id: client_id,
                current_balance: counter.current,
                last_energy: energy.current,
                telegram_reward_level: telegramRewardLevel
            },
        })
            .then((response) => {

                setWaitMessage(false);
                setTelegramRewardLevel(1);
                setFarmUpdateCompleted(true);
                setRewardsModal(false);
                counter.current = counter.current + 5000;
                setTimeout(() => {
                    setFarmUpdateCompleted(false);
                }, flyingItemsAnimTime)

            })
            .catch(error => {
                console.log(error);
            })
    };


    const xRewardChangeRequest = () => {

        // controller.abort();

        axios.get(`${url}/rest/updateUserXReward`, {
            params: {
                tg_id: client_id,
                current_balance: counter.current + 5000,
                last_energy: energy.current,
                x_reward_level: 1,
            },
        })
            .then((response) => {
                setFarmUpdateCompleted(true);
                setRewardsModal(false);
                setXRewardLevel(1);
                counter.current = counter.current + 5000;
                setTimeout(() => {
                    setFarmUpdateCompleted(false);
                }, flyingItemsAnimTime)
            })
            .catch(error => {
                console.log(error);
            })
    };


    const patriotsRewardChangeRequest = (count) => {

        if (count >= 5 && patriotsRewardLevel === 0) {

            // controller.abort();

            setWaitMessage(false);

            axios.get(`${url}/rest/updateUserPatriotsReward`, {
                params: {
                    tg_id: client_id,
                    current_balance: counter.current,
                    last_energy: energy.current,
                    patriots_reward_level: 1
                },
            })
                .then((response) => {
                    counter.current = counter.current + 25000;
                    setPatriotsRewardLevel(1);
                    setFarmUpdateCompleted(true);
                    setRewardsModal(false);
                    setTimeout(() => {
                        setFarmUpdateCompleted(false);
                    }, flyingItemsAnimTime)

                })
                .catch(error => {
                    console.log(error);
                })

        }

    };


    // Patriots Schema =====================


    const patriotsSchema = {
        friend_tg_id: 6935019187,
        first_name: "Артур",
        is_premium: "false",
        reward_taken: false,
        time_stamp: 1720654525043,
        tokens_per_hour: 1250,
        trump_level: 1,
        username: "artur_test123",
        firstname: "Артур",
        lastname: ''
    };




    const [patriotsList, setPatriotsList] = useState([])

    const patriotsListRequest = (count) => {


        axios.get(`${urlNew}/api/v1/referrals`, {
            headers: {
                "X-Auth-Token": authData.token,
            },
        })
            .then((response) => {

                setPatriotsList(response.data);

                console.log(response.data);

            })
            .catch(error => {
                // console.log(error);
                setErrorMessageText('Failed To Load Patriots');
                setErrorMessage(true);
            })

    };






    /*

    db.users.updateMany({}, {
        $set: {
            friends: [
                {
                    friend_tg_id: 6935019187,
                    first_name: "Артур",
                    is_premium: "false",
                    reward_taken: false,
                    time_stamp: 1720654525043,
                    tokens_per_hour: 1250,
                    trump_level: 1,
                    username: "artur_test123",
                    firstname: "Артур",
                    lastname: ''
                },
                {
                    friend_tg_id: 7471377646,
                    first_name: "Valentina",
                    is_premium: "false",
                    reward_taken: false,
                    time_stamp: 1720656525043,
                    tokens_per_hour: 3500,
                    trump_level: 3,
                    username: "valentina_tp_test",
                    firstname: "Valentina",
                    lastname: ''
                }
            ]
        }
    })

    */


    // PlayNav =====================

    const [selectedItem, setSelectedItem] = useState(3);

    useEffect(() => {
        selectionChanged();

        if (selectedItem === 3) {
            document.documentElement.style.setProperty(
                "overflow",
                "clip",
                "important"
            );
            document.getElementsByClassName('play-page')[0].style.setProperty(
                "overflow",
                "clip",
                "important"
            );
        }

    }, [selectedItem, boostOpen, farmNav]);

    useEffect(() => {
        setBoostOpen(false)
    }, [selectedItem]);


    // Messages =====================

    const [waitMessage, setWaitMessage] = useState(false);
    const [waitMessageText, setWaitMessageText] = useState('Loading..');
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('Success!');
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('Error =/');


    useEffect(() => {
        setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);
        setTimeout(() => {
            setSuccessMessageText('Success!');
        }, 4000)
    }, [successMessage]);


    useEffect(() => {
        setTimeout(() => {
            setWaitMessage(false);
        }, 3000);
        setTimeout(() => {
            setWaitMessageText('Loading..');
        }, 4000)
    }, [waitMessage]);


    useEffect(() => {

        setTimeout(() => {
            setErrorMessage(false);
        }, 3000);

        if (errorMessage !== 'Invalid Countersign') {
            setTimeout(() => {
                setErrorMessageText('Error =/');
            }, 4000);
        }
    }, [errorMessage])


    // Flying Items Animation

    const [flyingItemsImg, setFlyingItemsImg] = useState(vote_gold);

    const flyingItemsAnimTime = 1500;


    useEffect(() => {
        setTimeout(() => {
            setFlyingItemsImg(vote_gold)
        }, 2000)
    }, [flyingItemsImg]);


    return (
        <div className={`${welcomeVisible ? 'disabled' : ''} play-page`} id={'play-page'}>

            <Welcome visible={welcomeVisible}/>

            <DesktopBlock visible={desktopBlock}/>


            <div className={'play-bottom-fade'}></div>


            <div className={'falling-coins-animation-container'}>

                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>
                <img
                    className={`${farmUpdateCompleted || boostUpdateCompleted || energyBoostUpdateCompleted ? 'active' : ''}`}
                    src={flyingItemsImg} style={flyingItemsImg !== vote_gold ? {transform: 'scale(175%)'} : {}}/>

            </div>


            <div className={'messages-container'}>

                <div className={`${waitMessage ? 'active' : ''} wait message-item`}>
                    {waitMessageText}
                </div>

                <div className={`${successMessage ? 'active' : ''} success message-item`}>
                    {successMessageText}
                </div>

                <div className={`${errorMessage ? 'active' : ''} error message-item`}>
                    {errorMessageText}
                </div>

            </div>


            {/* Earned Offline Modal */}


            <div className={`${earnedOfflineModal ? 'active' : ''} modal-wrapper`}>

                <div
                    className={`${earnedOfflineModal ? 'active' : ''} modal-overlay`}
                    onClick={() => {
                        setBoostUpdateCompleted(true);
                        setEarnedOfflineModal(false);
                        setTimeout(() => {
                            setBoostUpdateCompleted(false);
                        }, flyingItemsAnimTime)
                    }}
                ></div>

                <div className={`${earnedOfflineModal ? 'active' : ''} farm modal-container`}>

                    <div className={`${earnedOfflineModal ? 'active' : ''} offline centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            {
                                client_language_code === 'ru' ?
                                    'С возвращением!'
                                    :
                                    'Welcome back!'
                            }
                        </h3>


                        <div className={'welcome-profile-img-container'}>
                            <img className={`${trumpLevel.current === 1 ? 'active' : ''} welcome-profile-img`}
                                 src={patriot1}/>
                            <img className={`${trumpLevel.current === 2 ? 'active' : ''} welcome-profile-img`}
                                 src={voter2}/>
                            <img className={`${trumpLevel.current === 3 ? 'active' : ''} welcome-profile-img`}
                                 src={volunteer3}/>
                            <img className={`${trumpLevel.current === 4 ? 'active' : ''} welcome-profile-img`}
                                 src={election_observer4}/>
                            <img className={`${trumpLevel.current === 5 ? 'active' : ''} welcome-profile-img`}
                                 src={personal_aide5}/>
                            <img className={`${trumpLevel.current === 6 ? 'active' : ''} welcome-profile-img`}
                                 src={chief_of_staff6}/>
                            <img className={`${trumpLevel.current === 7 ? 'active' : ''} welcome-profile-img`}
                                 src={campaign_manager7}/>
                            <img className={`${trumpLevel.current === 8 ? 'active' : ''} welcome-profile-img`}
                                 src={trumpump_vp8}/>
                            <img className={`${trumpLevel.current === 9 ? 'active' : ''} welcome-profile-img`}
                                 src={trumpump_svp9}/>
                            <img className={`${trumpLevel.current === 10 ? 'active' : ''} welcome-profile-img`}
                                 src={trumpump_evp10}/>
                            <div className={'head-profile-img-shadow'}></div>
                        </div>


                        <p className={'farm modal-content-desc-p'}>
                            {
                                client_language_code === 'ru' ?
                                    'Ваш доход в оффлайне составил'
                                    :
                                    'While being offline, you earned'
                            }
                        </p>

                        <div className={'big farm modal-content-char-item'}>
                            <img src={vote_gold}/>
                            <h3>
                                {
                                    convertNumberToShortString(Number(welcomeBackAmount))
                                }
                            </h3>
                        </div>

                        {/*<div className={'boost modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={farm_img}/>
                                <h5>
                                    +{
                                    convertNumberToShortString(earnedOffline)
                                }
                                    &nbsp;
                                    {
                                        ''
                                    }
                                </h5>
                            </div>

                            <div
                                className={'farm modal-content-char-item'}
                                onClick={() => {
                                    if (patriotsCount === 0) {
                                        setSelectedItem(1);

                                        counter.current = counter.current + Math.round(Number(earnedOffline));
                                        setBoostUpdateCompleted(true);
                                        setEarnedOfflineModal(false);
                                        sendCheckPointRequest(true);
                                        setTimeout(() => {
                                            setBoostUpdateCompleted(false);
                                        }, 2000)
                                    }
                                }}
                            >
                                <img src={squad}/>
                                <h5>
                                    {
                                        patriotsCount === 0 ?
                                            `${client_language_code == 'ru' ? 'Пригласить' : 'Invite'}`
                                            :
                                            `+${convertNumberToShortString(Math.round(patriotsFarmCalc))}`
                                    }
                                </h5>
                            </div>

                        </div>*/}


                    </div>

                    <button
                        className={`modal btn round full white`}
                        onClick={() => {
                            setBoostUpdateCompleted(true);
                            setEarnedOfflineModal(false);
                            setTimeout(() => {
                                setBoostUpdateCompleted(false);
                            }, flyingItemsAnimTime)
                        }}
                    >
                        {
                            client_language_code == 'ru' ?
                                'Продолжить PUMP'
                                :
                                'Continue to PUMP'
                        }
                    </button>

                </div>

            </div>


            <PlayNav selectedItem={selectedItem} setSelectedItem={setSelectedItem} setBoostOpen={setBoostOpen}/>


            <DebtModal
                debtModal={debtModal}
                setDebtModal={setDebtModal}
                setDebtInvestModal={setDebtInvestModal}
                debtInvestModal={debtInvestModal}
                treasuryInvestment={treasuryInvestment}
                urlNew={urlNew}
                authStatus={authStatus}
                authToken={authToken}
                setReloadHandle={setReloadHandle}
            />

            <DebtInvestModal
                debtInvestModal={debtInvestModal}
                setDebtInvestModal={setDebtInvestModal}
                treasuryInvestment={treasuryInvestment}
                urlNew={urlNew}
                authToken={authToken}
                setReloadHandle={setReloadHandle}
            />


            {/* ===== Farm Modal */}

            <FarmModal

                patriotsCount={patriotsCount}

                friendsUserArray={friendsUserArray}

                trumpLevel={trumpLevel}

                updateFarmRequest={updateFarmRequest}

                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                waitMessage={waitMessage}
                setWaitMessage={setWaitMessage}

                counter={counter}
                farmUpdateLoading={farmUpdateLoading}
                farmUserArrayUnfiltered={farmUserArrayUnfiltered}
                farmUserArray={farmUserArray}
                farmModalItem={farmModalItem}
                farmModal={farmModal}
                setFarmModal={setFarmModal}
            />


            {/*Boost Block*/}


            <div className={`${boostOpen ? 'active' : ''} boost-block`} id={'boost-block'}>

                <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal}/>

                <PlayBalance
                    rewardsModal={rewardsModal}
                    trumpLevelProps={trumpLevel}
                    counter={counter}
                    counterTemp={counterTemp}
                    counterState={counterState}
                    tokensPerClick={tokensPerClick}
                    tokensPerHour={tokensPerHour}
                    checkPointLoading={checkPointLoading}
                    isRageMode={rageMode}
                    changeNav={setSelectedItem}
                    setBoostsOpen={setBoostOpen}
                    setLevelsModal={setLevelsModal}
                />


                <div className={'boost-container'}>


                    <h5 className={'rewards-type'}>
                        Daily Boosts
                    </h5>

                    {/* Rage Mode */}

                    {
                        tempBoostsArray.map((item, i) =>

                            <div className={'temp-boost-container'}>

                                <div
                                    className={`${Math.floor(Date.now() / 1000) < boostUsage.find(b => b.id === item.id)?.restore_at ? 'disabled' : ''} temp boost-item`}
                                    onClick={() => {
                                        updateTempBoostRequest(item.id)
                                    }}
                                >

                                    <img src={
                                        item.id === 1 ?
                                            rage_mode
                                            :
                                            item.id === 2 ?
                                                energy_drink
                                                :
                                                ''
                                    }/>

                                    <div className={'boost-item-text'}>

                                        <div className={'boost-item-text-top'}>
                                            <h6>
                                                {item.name}
                                            </h6>

                                            <span>

                                                {item.description}

                                            </span>
                                        </div>

                                        <div className={'boost-item-text-bott'}>

                                            <div className={'boost-item-text-bott-item'}>
                                                <img src={vote_gold}/>
                                                <p>
                                                    Free
                                                </p>
                                            </div>

                                            <div className={'boost-item-text-bott-item'}>
                                                <p>
                                                    {
                                                        Math.floor(Date.now() / 1000) < boostUsage.find(b => b.id === item.id)?.restore_at ?
                                                            `${client_language_code == 'ru' ? 'Осталось' : 'Remaining'} ${sToTime(boostUsage.find(b => b.id === item.id)?.restore_at - Math.floor(Date.now() / 1000))}`
                                                            :
                                                            `${client_language_code == 'ru' ? 'Применить' : 'Ready To Use'}`
                                                    }
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        )
                    }


                    {/*<div className={'temp-boost-container'}>

                         Restore Energy

                        <divboostThr
                            className={`${(Date.now() - eeLevel) < (4 * 3600000) ? 'disabled' : ''} temp boost-item`}
                            onClick={() => {
                                updateTempBoostRequest(3)
                            }}
                        >

                            <img src={energy_drink}/>

                            <div className={'boost-item-text'}>

                                <div className={'boost-item-text-top'}>
                                    <h6>
                                        Restore Energy
                                    </h6>

                                    <span>
                                        {
                                            client_language_code == 'ru' ?
                                                'Восстановить энергию до максимума'
                                                :
                                                'Replenish your energy to the max'
                                        }
                                    </span>
                                </div>

                                <div className={'boost-item-text-bott'}>

                                    <div className={'boost-item-text-bott-item'}>
                                        <img src={vote_gold}/>
                                        <p>
                                            Free
                                        </p>
                                    </div>

                                    <div className={'boost-item-text-bott-item'}>
                                        <p>
                                            {
                                                (Date.now() - boostThreeLevel) < (4 * 3600000) ?
                                                    // `Remaining ${Math.abs((((4 * 3600000) - Date.now() + boostThreeLevel) / 3600000)-1)?.toFixed(0)}H ${Math.abs((((4 * 3600000) - Date.now() + boostThreeLevel) / 60000) % 60 - 1)?.toFixed(0)}M`
                                                    `${client_language_code == 'ru' ? 'Осталось' : 'Remaining'} ${msToTime((4 * 3600000) + boostThreeLevel - Date.now())}`
                                                    :
                                                    `${client_language_code == 'ru' ? 'Применить' : 'Ready To Use'}`
                                            }
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>*/}


                    <h5 className={'rewards-type'}>
                        General Boosts
                    </h5>


                    {
                        boostSchema.map((boost, i) =>
                            <div
                                className={'boost-item'}
                                onClick={() => {
                                    setBoostModalItem(boost);
                                    setBoostModal(true)
                                }}
                            >

                                <img src={boost.img}/>

                                <div className={'boost-item-text'}>

                                    <div className={'boost-item-text-top'}>
                                        <h6>
                                            {boost.name}
                                        </h6>

                                        <span>
                                            {
                                                client_language_code == 'ru' ?
                                                    boost.desc_ru
                                                    :
                                                    boost.desc
                                            }
                                        </span>
                                    </div>

                                    <div className={'boost-item-text-bott'}>

                                        <div className={'boost-item-text-bott-item'}>
                                            <img src={vote_gold}/>
                                            <p>
                                                {convertNumberToShortString(boost?.boostLevels[boost?.id === 1 ? boostOneLevel : boostTwoLevel]?.price)}
                                            </p>
                                        </div>

                                        <div className={'boost-item-text-bott-item'}>
                                            <p>
                                                {/*{boost.id === 1 ? `+1 PUMP Power` : `+500 Max Energy`}*/}
                                                LVL&nbsp;
                                                {
                                                    boost?.boostLevels[boost?.id === 1 ? boostOneLevel : boostTwoLevel]?.level
                                                }

                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        )
                    }


                </div>


                <div className={'play-bottom-container'}>

                    <div
                        className={`play-bottom-energy-container`}
                    >

                        <img src={energy_img}/>

                        <div className={`play-bottom-energy-text`}>
                            <h6 className={'mono'}>
                                {
                                    (energy.current)?.toLocaleString(undefined, {maximumFractionDigits: 0})
                                }
                            </h6>
                            <p>
                                {
                                    `Out Of ${energyMax?.toLocaleString(undefined, {maximumFractionDigits: 0})}`
                                }
                            </p>
                        </div>

                    </div>


                    <div
                        className={`${rageMode ? 'active' : ''} rage blue play-bottom-energy-container`}
                    >

                        <img src={rage_mode} style={{transform: 'scale(125%) translateX(-2px)'}}/>

                        <div className={`blue play-bottom-energy-text`}>
                            <h6 className={'mono'}>
                                PUMP!
                            </h6>
                            <p>
                                {
                                    `Ends In ${rageModeCounter}S`
                                }
                            </p>
                        </div>

                    </div>


                    <div
                        className={'boost play-bottom-energy-container'}
                        onClick={() => {
                            setBoostOpen(prev => !prev);
                        }}
                    >

                        <img src={back}/>

                        <div className={'boost play-bottom-energy-text'}>
                            <h6>
                                Back
                            </h6>
                            <p>
                                To PUMP
                            </p>
                        </div>

                    </div>

                </div>


            </div>


            {/* Boost Modal */}


            <div className={`${boostModal ? 'active' : ''} modal-wrapper`}>

                <div className={`${boostModal ? 'active' : ''} modal-overlay`}
                     onClick={() => setBoostModal(false)}></div>

                <div className={`${boostModal ? 'active' : ''} farm modal-container`}>

                    <div className={`${boostModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            {boostModalItem?.name}
                        </h3>

                        <h4 className={'modal-content-header-small'}>
                            LVL {boostModalItem?.boostLevels[boostModalItem.id === 1 ? boostOneLevel : boostTwoLevel]?.level}
                        </h4>

                        <div className={'farm modal-content-img-container'}>
                            <img className={'farm modal-content-img'} src={boostModalItem?.img}/>
                            <div className={'farm-item-img-container-shadow'}></div>
                        </div>

                        <p className={'farm modal-content-desc-p'}>
                            {
                                client_language_code == 'ru' ?
                                    boostModalItem?.desc_ru
                                    :
                                    boostModalItem?.desc
                            }
                        </p>

                        <div className={'boost modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={vote_gold}/>
                                <h5>
                                    {
                                        convertNumberToShortString(boostModalItem?.boostLevels[boostModalItem.id === 1 ? boostOneLevel : boostTwoLevel]?.price)
                                    }
                                </h5>
                            </div>

                            <div className={'farm modal-content-char-item'}>
                                <img src={boostModalItem.id === 1 ? rocket : energy_img}/>
                                <h5>
                                    +{boostModalItem.id === 1 ? '1' : '500'}
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`${(counter.current < boostModalItem?.boostLevels[boostModalItem.id === 1 ? boostOneLevel : boostTwoLevel]?.price) || waitMessage || boostUpdateLoading ? 'disabled' : ''} modal btn round full white`}
                        onClick={() => {
                            updateBoostRequest(
                                boostModalItem?.id,
                                boostModalItem?.level,
                                boostModalItem?.price,
                                boostModalItem?.boost
                            );
                        }}
                    >
                        {counter.current >= boostModalItem?.boostLevels[boostModalItem.id === 1 ? boostOneLevel : boostTwoLevel]?.price ? `${client_language_code == 'ru' ? 'Улучшить' : 'Upgrade'}` : `${client_language_code == 'ru' ? 'Недостаточно средств' : 'Insufficient Funds'}`}
                    </button>

                </div>

            </div>


            {/* Rewards Modal */}


            <div className={`${rewardsModal ? 'active' : ''} modal-wrapper`}>

                <div className={`${rewardsModal ? 'active' : ''} modal-overlay`}
                     onClick={() => setRewardsModal(false)}></div>

                <div className={`${rewardsModal ? 'active' : ''} ${rewardsModalItem.type === 3 ? 'red' : rewardsModalItem.type === 2 ? 'white' : ''} farm modal-container`}>

                    <div className={`${rewardsModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            {rewardsModalItem?.name}
                        </h3>

                        {/*<h4 className={'modal-content-header-small'}>
                            {rewardsModalItem?.type === 1 ? 'Daily Task' : 'General Task'}
                        </h4>*/}


                        {/* Daily Reward Table */}

                        {
                            rewardsModalItem?.id === 1 ?
                                <div className={'daily-reward-table'}>

                                    {
                                        dailyRewardsSchema.map((reward, i) =>
                                            <div
                                                className={`${(i === (rewardsModalItem?.step - 1)) && (rewardsModalItem?.available) ? 'active' : ''} ${((i) < (rewardsModalItem?.step - 1)) ? 'completed' : ''} daily-reward-table-item`}>

                                                <p>
                                                    Day {i + 1}
                                                </p>

                                                <img src={vote_gold}/>

                                                <h5>
                                                    {convertNumberToShortString(reward)}
                                                </h5>

                                            </div>
                                        )
                                    }

                                </div>
                                :
                                // Patriots Reward Table
                                rewardsModalItem?.id === 15 ?
                                    <div
                                        className={'daily-reward-table'}
                                        style={{gridTemplateColumns: 'repeat(3,1fr)'}}
                                        // onClick={() => setPatriotsCount(prev => prev + 1)}
                                    >

                                        {
                                            patriotRewardSchema.map((reward, i) =>
                                                <div
                                                    className={`inv ${patriotsCount >= reward.amount && patriotRewardSchema[i].step !== 1 && reward.step !== 2 ? 'active-inv' : ''} ${patriotsCount >= reward.amount && reward.step === 2 ? 'completed-inv' : ''} daily-reward-table-item`}
                                                >

                                                    <p className={'daily-reward-table-item-p'}>
                                                        {convertNumberToShortString(reward.amount)} Patriots
                                                    </p>

                                                    <img
                                                        src={reward.image}
                                                        style={{
                                                            height: '60px',
                                                            width: '60px',
                                                            borderRadius: '500px',
                                                            // outline: '1px solid #fff'
                                                        }}
                                                    />

                                                    <h5 className={'daily-reward-table-item-h5'}>
                                                        {convertNumberToShortString(reward.price)}
                                                    </h5>

                                                </div>
                                            )
                                        }

                                    </div>
                                    :
                                    rewardsModalItem?.type === 2 || rewardsModalItem?.type === 3 ?
                                        <div className={'rewards modal-content-img-container'}
                                             style={rewardsModalItem?.id === 15 || rewardsModalItem?.id === 4 ? {outline: '2px solid #ffffff'} : {}}>
                                            <img className={'farm modal-content-img'} src={rewardsModalItem?.image}/>
                                        </div>
                                        :
                                        ''
                        }





                        {/* Daily Countersign Reward Cypher */}

                        {
                            rewardsModalItem?.id === 3 ?
                                <Countersign
                                    rewardsModal={rewardsModal}
                                    setRewardsModal={setRewardsModal}
                                    rewardsStatusRequest={rewardsStatusRequest}
                                    counter={counter}
                                    setFarmUpdateCompleted={setFarmUpdateCompleted}
                                    available={rewardsModalItem?.available}
                                    authToken={authToken}
                                    urlNew={urlNew}
                                    setSuccessMessageText={setSuccessMessageText}
                                    setSuccessMessage={setSuccessMessage}
                                    successMessage={successMessage}
                                    setWaitMessage={setWaitMessage}
                                    waitMessage={waitMessage}
                                    setErrorMessageText={setErrorMessageText}
                                    setErrorMessage={setErrorMessage}
                                />
                                :
                                ''
                        }



                        {/* Vote Election Reward */}

                        {
                            rewardsModalItem?.id === 5 ?
                                <div className={'vote-modal-choose-container'}>

                                    <div className={'vote-modal-choose-item'}>

                                        <div className={'rewards modal-content-img-container'}>
                                            <img className={'farm modal-content-img'} src={rewardsModalItem?.image}/>
                                        </div>

                                        <button
                                            className={`${rewardsStatusData.find(task => task.id === 2).available ? '' : 'disabled'} btn red round`}
                                            onClick={() => {
                                                rewardChangeRequest(2, 1)
                                                setVoteRewardLevel(1);
                                            }}>
                                            Trump
                                        </button>

                                    </div>

                                    <div className={'vote-modal-choose-item'}>

                                        <div className={'rewards modal-content-img-container'}>
                                            <img className={'farm modal-content-img'}
                                                 src={rewardsModalItem?.image_two}/>
                                        </div>

                                        <button
                                            className={`${rewardsStatusData.find(task => task.id === 2).available ? '' : 'disabled'} btn primary round`}
                                            onClick={() => {
                                                rewardChangeRequest(2, 2)
                                                setVoteRewardLevel(2);
                                            }}>
                                            Kamala
                                        </button>

                                    </div>

                                </div>
                                :
                                ''
                        }


                        {
                            rewardsModalItem?.type === 1 ?
                                ''
                                :
                                rewardsModalItem?.id === 4 ?

                                    <div
                                        style={{alignSelf: 'center', justifySelf: 'center', marginTop: '12px'}}
                                    >
                                        <button
                                            className={`btn round ${rewardsModalItem?.type === 3 ? 'red' : rewardsModalItem?.type === 2 ? 'primary' : 'primary'}`}
                                            onClick={async () => {
                                                if (rewardsModalItem.id === 4) {
                                                    await window?.Telegram?.WebApp?.shareToStory(
                                                        'https://trumpump.app/animation/story_basic.mp4',
                                                        {
                                                            text: `Join in at ${referralCopyLink}`,
                                                            widget_link:
                                                                {
                                                                    name: 'JOIN IN',
                                                                    url: referralCopyLink
                                                                }
                                                        }
                                                    );
                                                    await rewardChangeRequest(13);
                                                }
                                            }}
                                        >
                                            {
                                                rewardsModalItem?.available ?
                                                    'PUMP'
                                                    :
                                                    'PUMPED'
                                            }
                                        </button>
                                    </div>

                                    :

                                    <div
                                        style={{alignSelf: 'center', justifySelf: 'center', marginTop: '12px'}}
                                        onClick={() => {
                                            window.open(rewardsModalItem?.link, '_blank')
                                        }}
                                    >
                                        <button
                                            className={`btn round ${rewardsModalItem?.type === 3 ? 'red' : rewardsModalItem?.type === 2 ? 'primary' : 'primary'}`}
                                            onClick={() => {
                                                rewardChangeRequest(18)
                                            }}
                                        >
                                            {
                                                rewardsModalItem?.id === 444 ?
                                                    rewardsModalItem?.available ?
                                                        'PUMP'
                                                        :
                                                        'PUMPED'
                                                    :
                                                rewardsModalItem?.id === 5 ?
                                                    'Vote'
                                                    :
                                                    rewardsModalItem?.id === 15 ?
                                                        'Invite'
                                                        :
                                                        rewardsModalItem?.type === 2 ?
                                                            rewardsModalItem?.id === 11 ?
                                                                'Join'
                                                                :
                                                                'Follow'
                                                            :
                                                            rewardsModalItem?.available ?
                                                                'Collect'
                                                                :
                                                                'Collected'
                                            }
                                        </button>
                                    </div>

                        }

                        <p className={'farm modal-content-desc-p'}>
                            {
                                rewardsModalItem?.desc
                            }
                        </p>

                        <div className={'one modal-content-chars-container'}
                             style={rewardsModalItem?.id === 1 || rewardsModalItem?.id === 15 ? {display: 'none'} : {}}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={vote_gold}/>
                                <h5>
                                    {
                                        rewardsModalItem?.id === 4 ?
                                            '300K'
                                            :
                                        rewardsModalItem?.id === 444 ?
                                            '250K'
                                            :
                                            rewardsModalItem?.id === 3 ?
                                                '50K'
                                                :
                                                rewardsModalItem?.id === 5 ?
                                                    '?'
                                                    :
                                                        rewardsModalItem?.type !== 1 ?
                                                            rewardsModalItem?.id === 11 ?
                                                                '100K'
                                                                :
                                                                '50K'
                                                            :
                                                            convertNumberToShortString(dailyRewardsSchema[(Date.now() - dailyRewardDate) < (3600000 * 24) ? dailyRewardLevel : dailyRewardLevel + 1])
                                    }
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`${
                            (rewardsModalItem?.id === 15 && patriotRewardLevelAvailable > 1 && patriotsCount >= 5) || rewardsModalItem?.available || rewardsModalItem?.id === 2 || rewardsModalItem?.id === 3 || rewardsModalItem?.id === 4 || rewardsModalItem?.id === 444 ? '' : 'disabled'
                        } modal btn round full white`}
                        onClick={() => {
                            rewardsModalItem?.id === 5 || rewardsModalItem?.id === 3 || rewardsModalItem?.id === 4 || rewardsModalItem?.id === 444 ?
                                setRewardsModal(false)
                                :
                                    rewardsModalItem?.type === 1 ?
                                        rewardChangeRequest(1)
                                        :
                                        rewardsModalItem?.id === 11 ?
                                            rewardChangeRequest(10)
                                            :
                                            rewardsModalItem?.id === 12 ?
                                                rewardChangeRequest(11)
                                                :
                                                /*rewardsModalItem?.id === 444 ?
                                                    rewardChangeRequest(18)
                                                    :*/

                                                rewardsModalItem?.id === 15 ?
                                                    rewardChangeRequest(patriotRewardLevelAvailable)
                                                    :
                                                    {}
                        }}
                    >
                        {
                            rewardsModalItem?.id === 15 && patriotsCount < 5  ?
                                'Not Enough Patriots'
                                :
                                rewardsModalItem?.id === 15 && patriotRewardLevelAvailable > 1  ?
                                    'Collect'
                                    :
                                    rewardsModalItem?.id === 5 || (rewardsModalItem?.id === 3) || (rewardsModalItem?.id === 4) || (rewardsModalItem?.id === 444) ?
                                        'Continue to PUMP'
                                        :
                                        rewardsModalItem?.available ?
                                            'Collect'
                                            :
                                            'Collected'
                        }
                    </button>

                </div>

            </div>


            {/* Levels Modal */}


            <div className={`${levelsModal ? 'active' : ''} modal-wrapper`}>

                <div className={`${levelsModal ? 'active' : ''} modal-overlay`}
                     onClick={() => setLevelsModal(false)}></div>

                <div className={`${levelsModal ? 'active' : ''} farm modal-container`}>

                    <div className={`${levelsModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            Levels
                        </h3>


                        <div className={'levels-modal-table-wrapper'}>

                            {/*<div className={'levels-modal-table-container-shadow'}></div>*/}

                            <div className={'levels-modal-table-container'}>

                                {
                                    userLevels.map((level, i) =>

                                        <div className={`levels-modal-table-item`}>

                                            <img src={level.img} style={{outline: '2px solid #ffffff'}}/>

                                            <p style={{
                                                marginTop: '12px',
                                                marginBottom: '3px',
                                                fontWeight: '600',
                                                fontSize: '14px'
                                            }}>
                                                Level {i + 1}
                                            </p>

                                            <p>
                                                {(trumpLevelsSchema[i])?.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                            </p>

                                            <h6>
                                                {level.label}
                                            </h6>

                                        </div>
                                    )}

                            </div>
                        </div>


                        <p style={{marginTop: '20px', marginBottom: '-8px', fontWeight: '600'}}>
                            For Every Level You Get
                        </p>

                        <div className={'boost modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={energy_img}/>
                                <h5>
                                    +500
                                </h5>
                            </div>

                            <div className={'farm modal-content-char-item'}>
                                <img src={rocket}/>
                                <h5>
                                    +1
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`modal btn round full white`}
                        onClick={() => setLevelsModal(false)}
                    >
                        Continue to PUMP
                    </button>

                </div>

            </div>


            {/* Level Change Modal */}


            <div
                className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} modal-wrapper`}>

                <div
                    className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} modal-overlay`}
                    onClick={() => setLevelChangeModal(false)}></div>

                <div
                    className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} margin modal-container`}>

                    <div
                        className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            Next Level!
                        </h3>


                        <div className={`levels-modal-table-item`}>

                            <img src={userLevels[trumpLevel.current - 1]?.img} style={{outline: '2px solid #ffffff'}}/>

                            <p style={{marginTop: '12px', marginBottom: '3px', fontWeight: '600', fontSize: '14px'}}>
                                Level {trumpLevel.current}
                            </p>

                            <h6>
                                {userLevels[trumpLevel.current - 1]?.label}
                            </h6>

                        </div>


                        <p style={{marginTop: '20px', marginBottom: '-8px', fontWeight: '600'}}>
                            You did a tremendous job! Your reward:
                        </p>

                        <div className={'boost modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={energy_img}/>
                                <h5>
                                    +500
                                </h5>
                            </div>

                            <div className={'farm modal-content-char-item'}>
                                <img src={rocket}/>
                                <h5>
                                    +1
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`modal btn round full white`}
                        onClick={() => setLevelChangeModal(false)}
                    >
                        Continue to PUMP
                    </button>

                </div>

            </div>


            {/* Patriot Reward Modal */}


            {/*<div className={`${(patriotsRewardNotTaken > 0) && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} modal-wrapper`}>

                <div className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} modal-overlay`} onClick={() => {
                    counter.current = counter.current + patriotsRewardNotTaken
                    setFarmUpdateCompleted(true);
                    setTimeout(() => {
                        setFarmUpdateCompleted(false);
                    }, 3500)
                    setPatriotsRewardNotTaken(0);
                }}></div>

                <div className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} margin modal-container`}>

                    <div className={`${levelChangeModal && !voteRewardModal && !earnedOfflineModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            New Patriot{patriotsRewardNotTakenCount > 1 ? 's' : ''} Joined!
                        </h3>


                        <div className={`levels-modal-table-item`}>

                            <img src={patriot1} style={{outline: '2px solid #ffffff'}} />

                            <h6 style={{marginTop: '18px', marginBottom: '3px', fontWeight: '600', fontSize: '14px'}}>
                                {patriotsRewardNotTakenCount} new Patriot{patriotsRewardNotTakenCount > 1 ? 's' : ''} joined with your referral.
                            </h6>

                        </div>


                        <p style={{marginTop: '20px', marginBottom: '-8px', fontWeight: '600'}}>
                            Your reward is:
                        </p>

                        <div className={'one modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={vote_gold} />
                                <h5>
                                    {patriotsRewardNotTaken}
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`modal btn round full white`}
                        onClick={() => {
                            counter.current = counter.current + patriotsRewardNotTaken
                            setFarmUpdateCompleted(true);
                            setTimeout(() => {
                                setFarmUpdateCompleted(false);
                            }, 3500)
                            setPatriotsRewardNotTaken(0);
                        }}
                    >
                        Collect
                    </button>

                </div>

            </div>*/}


            {/* Vote Reward Modal */}


            <div className={`${voteRewardModal ? 'active' : ''} modal-wrapper`}>

                <div className={`${voteRewardModal ? 'active' : ''} modal-overlay`}
                     onClick={() => setVoteRewardModal(false)}></div>

                <div className={`${voteRewardModal ? 'active' : ''} margin modal-container`}>

                    <div className={`${voteRewardModal ? 'active' : ''} centered modal-content`}>

                        <h3 className={'modal-content-header'}>
                            Go {voteRewardLevel === 1 ? 'Donald Trump' : 'Kamala Harris'}!
                        </h3>


                        <div className={`levels-modal-table-item`}>

                            <img src={
                                voteRewardLevel === 1 ?
                                    RewardsSchema.find(reward => reward.id === 5).image
                                    :
                                    RewardsSchema.find(reward => reward.id === 5).image_two
                            } style={{outline: '2px solid #ffffff'}}/>


                            {/*<h6 style={{marginTop: '18px'}}>
                                {
                                    voteRewardLevel === 1 ?
                                        'You Took The Right Path'
                                        :
                                        'You Took The Wrong Path'
                                }
                            </h6>*/}

                        </div>


                        <p style={{marginTop: '12px', marginBottom: '-8px', fontWeight: '600'}}>
                            {
                                voteRewardLevel === 1 ?
                                    `You voted for Donald Trump! A new tax cut has been enacted. ${voteRewardCoins > 10000 ? (voteRewardCoins?.toLocaleString()) : '10K'} coins received`
                                    :
                                    'You voted for Kamala Harris... Time to raise more taxes. You lost 5000 coins'
                            }
                        </p>

                        <div className={'one modal-content-chars-container'}>

                            <div className={'farm modal-content-char-item'}>
                                <img src={vote_gold}/>
                                <h5>
                                    {
                                        voteRewardLevel === 1 ?
                                            voteRewardCoins > 10000 ? (voteRewardCoins?.toLocaleString()) : '10K'
                                            :
                                            '-5K'
                                    }
                                </h5>
                            </div>

                        </div>


                    </div>

                    <button
                        className={`modal btn round full white`}
                        onClick={() => setVoteRewardModal(false)}
                    >
                        Continue to PUMP
                    </button>

                </div>

            </div>


            <Main
                trumpLevelState={trumpLevelState}
                setTrumpLevelState={setTrumpLevelState}
                animationMapping={animationMapping}
                levelChangeModal={levelChangeModal}
                authToken={authToken}
                urlNew={urlNew}
                setErrorMessage={setErrorMessage}
                setErrorMessageText={setErrorMessageText}
                setDebtModal={setDebtModal}
                setDebtInvestModal={setDebtInvestModal}
                rageModeCounter={rageModeCounter}
                energy={energy}
                energyMax={energyMax}
                voteBtnRef={voteBtnRef}
                rageMode={rageMode}
                trumpLevel={trumpLevel}
                boostOpen={boostOpen}
                setBoostOpen={setBoostOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                trumpLevelProps={trumpLevel}
                counter={counter}
                counterTemp={counterTemp}
                counterState={counterState}
                tokensPerClick={tokensPerClick}
                tokensPerHour={tokensPerHour}
                checkPointLoading={checkPointLoading}
                isRageMode={rageMode}
                changeNav={setSelectedItem}
                setBoostsOpen={setBoostOpen}
                setLevelsModal={setLevelsModal}
            />


            {/* ===== Farm Block */}


            <div className={`${selectedItem === 4 ? 'active' : ''} farm-block`} id={'farm-block'}>

                <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal}/>

                <PlayBalance
                    trumpLevelProps={trumpLevel}
                    counter={counter}
                    counterTemp={counterTemp}
                    counterState={counterState}
                    tokensPerClick={tokensPerClick}
                    tokensPerHour={tokensPerHour}
                    checkPointLoading={checkPointLoading}
                    isRageMode={rageMode}
                    changeNav={setSelectedItem}
                    setBoostsOpen={setBoostOpen}
                    setLevelsModal={setLevelsModal}
                />


                <div className={'farm-nav-container'}>

                    <div
                        className={`${farmNav === 2 ? 'active' : ''} farm-nav-item`}
                        onClick={() => changeFarmNav(2)}
                    >
                        {/*Headquarters*/}
                        HQ
                    </div>

                    <div
                        className={`${farmNav === 1 ? 'active' : ''} farm-nav-item`}
                        onClick={() => changeFarmNav(1)}
                    >
                        {/*Trumpology*/}
                        MAGA
                    </div>

                    <div
                        className={`${farmNav === 3 ? 'active' : ''} farm-nav-item`}
                        onClick={() => changeFarmNav(3)}
                    >
                        SWAG
                    </div>

                    <div
                        className={`${farmNav === 4 ? 'active' : ''} farm-nav-item`}
                        onClick={() => changeFarmNav(4)}
                    >
                        Exclusive
                    </div>

                </div>


                <div className={'farm-desc-container'}>

                    <p>
                        {
                            farmNav === 1 ?
                                'Unlock all cards and get hourly income. Cards work offline for 4 hours.'
                                :
                                farmNav === 2 ?
                                    'Manage your campaign headquarters, upgrade and earn hourly income. Cards work offline for 4 hours.'
                                    :
                                    farmNav === 3 ?
                                        'Shine your well-deserved riches and earn an hourly income. Cards work offline for 4 hours.'
                                        :
                                        farmNav === 4 ?
                                            'Exclusive luxury items for those who can afford it. Upgrade and earn hourly income offline without restrictions.'
                                            :
                                            ''
                        }
                    </p>

                </div>


                <div className={'farm-wrapper'}>

                    <div className={'farm-container'}>

                        {
                            farm.filter(filter => filter.type !== 1).map((item, i) =>

                                <div
                                    className={`${item.type === 1 || item.type === 4 ? 'vert' : ''} ${
                                        item.class === 1 || item.type === 4 ?
                                            'bronze'
                                            :
                                            item.class === 2 ?
                                                'silver'
                                                :
                                                item.class === 3 ?
                                                    'gold'
                                                    :
                                                    item.class === 4 ?
                                                        'platinum'
                                                        :
                                                        item.class === 5 ?
                                                            'diamond'
                                                            :
                                                            ''
                                    } farm-item`}
                                    style={{display: item.type === farmNav ? 'flex' : 'none'}}
                                    onTouchStart={() => {

                                        /*if (counter.current > item.price) {
                                            setTokensPerHour(prev => prev + 50);
                                            counter.current = counter.current - item.price;
                                        }*/

                                    }}

                                    onClick={() => {
                                        setFarmModalItem(item);
                                        setFarmModal(true);
                                    }}

                                >

                                    <div className={'farm-item-text'}>

                                        <div className={`${item.type === 1 ? 'disabled' : ''} farm-item-text-top`}>

                                            <div className={`${
                                                item.class === 1 || item.type === 4 ?
                                                    'bronze'
                                                    :
                                                    item.class === 2 ?
                                                        'silver'
                                                        :
                                                        item.class === 3 ?
                                                            'gold'
                                                            :
                                                            item.class === 4 ?
                                                                'platinum'
                                                                :
                                                                item.class === 5 ?
                                                                    'diamond'
                                                                    :
                                                                    ''
                                            } ${item.type === 1 || item.type === 4 ? 'nopadding' : ''} farm-item-img-container`}>

                                                <img className={`farm-item-img`} src={
                                                    (item.type === 1) ?
                                                        item.class === 1 || item.type === 4 ?
                                                            bronze_bar
                                                            :
                                                            item.class === 2 ?
                                                                silver_bar
                                                                :
                                                                item.class === 3 ?
                                                                    gold_bar
                                                                    :
                                                                    item.class === 4 ?
                                                                        platinum_bar
                                                                        :
                                                                        item.class === 5 ?
                                                                            diamond_bar
                                                                            :
                                                                            ''
                                                        :
                                                        item.img
                                                }
                                                />
                                                <div className={'farm-item-img-container-shadow'}></div>
                                            </div>

                                            <div className={'farm-item-top-text'}>

                                                {
                                                    item.type === 1 ?
                                                        ''
                                                        :
                                                        <h6>
                                                            {item.name}
                                                        </h6>
                                                }

                                                {
                                                    item.type === 1 ?
                                                        ''
                                                        :
                                                        <p className={'farm-item-desc'}>
                                                            PUMP/Hour
                                                        </p>
                                                }

                                                <div
                                                    className={`${item.farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.level === 0 ? '' : 'active'} farm-item-price-container pph`}>
                                                    {
                                                        <img src={farm_img}/>
                                                    }

                                                    <p>
                                                        {
                                                            farmUserArray?.find(farm => farm.id === item.id)?.level <= 1 ?
                                                                convertNumberToShortString(item.farm)
                                                                :
                                                                convertNumberToShortString(item.farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level - 1]?.total_farm)
                                                        }
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                        <div
                                            className={`${item.type === 1 || (item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount))) || (item.activation_trigger_type === 3 && trumpLevel.current < item.activation_trigger_goal) || (item.activation_trigger_type === 4 && patriotsCount < item.activation_trigger_goal) ? 'centered' : ''} farm-item-text-bott`}>

                                            {
                                                item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount)) || (item.activation_trigger_type === 3 && trumpLevel.current < item.activation_trigger_goal) || (item.activation_trigger_type === 4 && patriotsCount < item.activation_trigger_goal) ?
                                                    ''
                                                    :
                                                    item.type !== 1 ?
                                                        <p className={'farm-item-level'}>
                                                            LVL&nbsp;
                                                            {
                                                                farmUserArray?.find(farm => farm.id === item.id) ?
                                                                    farm[i].farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.level
                                                                    :
                                                                    farm[i].farmLevels[0]?.level
                                                            }
                                                        </p>
                                                        :
                                                        ''
                                            }

                                            <div className={'farm-item-price-container'} style={{overflow: 'hidden'}}>

                                                {
                                                    item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount)) || (item.activation_trigger_type === 3 && trumpLevel.current < item.activation_trigger_goal) || (item.activation_trigger_type === 4 && patriotsCount < item.activation_trigger_goal) ?
                                                        ''
                                                        :
                                                        <img src={vote_gold}/>
                                                }

                                                <p style={item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount)) ? {
                                                    maxWidth: '90px',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden'
                                                } : {}}>
                                                    {
                                                        item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount)) ?
                                                            farm.find(farm => farm.id === item.activation_trigger_goal)?.name
                                                            :
                                                            item.activation_trigger_type === 3 && trumpLevel.current < item.activation_trigger_goal ?
                                                                `Level ${item.activation_trigger_goal}`
                                                                :
                                                                (item.activation_trigger_type === 4 && patriotsCount < item.activation_trigger_goal) ?
                                                                    `${item.activation_trigger_goal} Patriots`
                                                                    :
                                                                    convertNumberToShortString(item.farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.price)
                                                    }
                                                </p>

                                                <p style={{marginLeft: '4px'}}>
                                                    {
                                                        item.activation_trigger_type === 2 && farmUserArray?.find(find => (find.id === item.activation_trigger_goal) && (find.level < item.activation_trigger_amount)) ?
                                                            ' ' + farm.find(farm => farm.id === item.activation_trigger_goal)?.farmLevels.find(find => find.level === item.activation_trigger_amount)?.level + ' LVL'
                                                            :
                                                            ''
                                                    }
                                                </p>


                                            </div>

                                        </div>

                                    </div>


                                </div>
                            )
                        }

                    </div>


                    {/* Farm MAGA */}

                    {
                        trumpologySchema.map((header, h) =>

                            <div className={`${h === 0 ? 'first-child' : ''} trumpology-container`}
                                 style={farmNav !== 1 ? {display: 'none'} : {display: 'flex'}}>

                                <div className={`${
                                    h === 0 ?
                                        'bronze'
                                        :
                                        h === 1 ?
                                            'silver'
                                            :
                                            h === 2 ?
                                                'gold'
                                                :
                                                h === 3 ?
                                                    'platinum'
                                                    :
                                                    h === 4 ?
                                                        'diamond'
                                                        :
                                                        ''
                                } trumpology-header`}>

                                    {header}

                                </div>

                                <div className={'farm-container'} style={{gridTemplateColumns: 'repeat(3,1fr)'}}>

                                    {
                                        farm.filter(filter => filter.class === h + 1).map((item, i) =>

                                            <div
                                                className={`${item.type === 1 || item.type === 4 ? 'vert' : ''} ${
                                                    item.class === 1 || item.type === 4 ?
                                                        'bronze'
                                                        :
                                                        item.class === 2 ?
                                                            'silver'
                                                            :
                                                            item.class === 3 ?
                                                                'gold'
                                                                :
                                                                item.class === 4 ?
                                                                    'platinum'
                                                                    :
                                                                    item.class === 5 ?
                                                                        'diamond'
                                                                        :
                                                                        ''
                                                } farm-item`}
                                                style={{display: item.type === farmNav ? 'flex' : 'none'}}
                                                onTouchStart={() => {

                                                }}

                                                onClick={() => {
                                                    setFarmModalItem(item);
                                                    setFarmModal(true);
                                                }}

                                            >

                                                <div className={'farm-item-text'}>


                                                    <div className={`farm-item-text-top`}>

                                                        <div className={`${
                                                            item.class === 1 || item.type === 4 ?
                                                                'bronze'
                                                                :
                                                                item.class === 2 ?
                                                                    'silver'
                                                                    :
                                                                    item.class === 3 ?
                                                                        'gold'
                                                                        :
                                                                        item.class === 4 ?
                                                                            'platinum'
                                                                            :
                                                                            item.class === 5 ?
                                                                                'diamond'
                                                                                :
                                                                                ''
                                                        } ${item.type === 1 || item.type === 4 ? 'nopadding' : ''} farm-item-img-container`}>

                                                            <img className={`farm-item-img`} src={
                                                                (item.type === 1) && farmUserArrayUnfiltered[item.id] === undefined ?
                                                                    item.class === 1 || item.type === 4 ?
                                                                        bronze_bar
                                                                        :
                                                                        item.class === 2 ?
                                                                            silver_bar
                                                                            :
                                                                            item.class === 3 ?
                                                                                gold_bar
                                                                                :
                                                                                item.class === 4 ?
                                                                                    platinum_bar
                                                                                    :
                                                                                    item.class === 5 ?
                                                                                        diamond_bar
                                                                                        :
                                                                                        ''
                                                                    :
                                                                    item.img}
                                                            />
                                                            <div className={'farm-item-img-container-shadow'}></div>
                                                        </div>

                                                    </div>


                                                    <div
                                                        className={`${item.type === 1 ? 'centered' : ''} farm-item-text-bott`}>

                                                        {
                                                            item.type !== 1 ?
                                                                <p className={'farm-item-level'}>
                                                                    LVL&nbsp;
                                                                    {
                                                                        farmUserArray?.find(farm => farm.id === item.id) ?
                                                                            farm[i].farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.level
                                                                            :
                                                                            farm[i].farmLevels[0]?.level
                                                                    }
                                                                </p>
                                                                :
                                                                ''
                                                        }

                                                        <div className={'farm-item-price-container'}>

                                                            {
                                                                ((item.farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.level >= 1) || ((farm?.filter(farm => (farm.class === (item.class - 1))) || !(farmUserArray?.find(find => find.id === farm.id)?.level >= 1)))?.length < 3) || ((item.activation_trigger_type === 1) && ((farm?.filter(farm => (farm.class === (item.class - 1)) && (farmUserArray?.find(find => find.id === farm.id)?.level >= 1)))?.length < 3) && (item.class !== 1)) ?
                                                                    ''
                                                                    :
                                                                    <img src={vote_gold}/>
                                                            }


                                                            <p>
                                                                {
                                                                    (item.activation_trigger_type === 1) && ((farm?.filter(farm => (farm.class === (item.class - 1)) && (farmUserArray?.find(find => find.id === farm.id)?.level >= 1)))?.length < 3) && (item.class !== 1) ?
                                                                        `3 ${
                                                                            item.class === 2 ?
                                                                                'Bronze'
                                                                                :
                                                                                item.class === 3 ?
                                                                                    'Silver'
                                                                                    :
                                                                                    item.class === 4 ?
                                                                                        'Gold'
                                                                                        :
                                                                                        item.class === 5 ?
                                                                                            'Platinum'
                                                                                            :
                                                                                            ''
                                                                        }`
                                                                        :
                                                                        item.farmLevels[farmUserArray?.find(farm => farm.id === item.id)?.level]?.level >= 1 ?
                                                                            `${client_language_code == 'ru' ? '' : 'Collected'}`
                                                                            :
                                                                            convertNumberToShortString(item.price)
                                                                }
                                                            </p>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        )
                                    }

                                </div>

                            </div>
                        )
                    }


                </div>


            </div>


            {/* Rewards Block */}


            <div className={`${selectedItem === 2 ? 'active' : ''} farm-block`} id={'farm-block'}>

                <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal}/>


                <img src={vote_gold} className={'rewards-main-img'}/>

                <h2 className={'rewards-main-header'}>
                    Earn More Coins
                </h2>


                <div className={'rewards-wrapper'}>


                    {/*<h5 className={'rewards-type'}>
                        PUMP Organization
                    </h5>

                    <div
                        className={`rewards red boost-item`}
                        onClick={() => {

                        }}
                    >

                        <img src={pump_organisation_img}/>

                        <div className={'boost-item-text'}>

                            <div className={'boost-item-text-top'}>
                                <h6>
                                    PUMP Organization
                                </h6>

                                <span className={'boost-item-span'}>
                                            Coming Soon...
                                        </span>
                            </div>

                            <div className={'boost-item-text-bott'}>

                                <div className={`boost-item-text-bott-item`}>
                                    <p>
                                        Stay Tuned!
                                    </p>
                                </div>

                                <div className={'boost-item-text-bott-item'}>
                                    <p className={'boost-item-p'}>

                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>*/}


                    {
                        RewardsSchema.map(item =>
                            <div className={'rewards-container'}>

                                <h5 className={'rewards-type'}
                                    style={item.id === 3 ||item.id === 44 || item.id === 11 ? {display: 'block'} : {display: 'none'}}
                                >

                                    {
                                        item.type === 3 ?
                                            'PUMP Organisation'
                                            :
                                        item.type === 1 ?
                                            'Daily Tasks'
                                            :
                                            'General Tasks'
                                    }
                                </h5>

                                <div
                                    // style={{display: client_id !== 5616427503 && client_id !== 6805718323 && item.id === 4 ? 'none' : 'flex'}}
                                    style={{
                                        pointerEvents: client_is_premium != true && item.id === 4  ? 'none' : 'all',
                                        // display: item.id >= 15 && item.id <= 19 && !item.visible ? 'none' : 'flex',
                                    }}
                                    className={`rewards ${(item.type !== 1) && (item.type !== 5) ? 'temp ' : ''} ${(item.type === 3) ? 'red ' : ''} ${item.type === 5 ? 'centered' : ''} boost-item`}
                                    onClick={() => {

                                        if (item.id !== 44) {
                                            setRewardsModal(true);
                                            setRewardsModalItem(item);
                                        }

                                    }}
                                >

                                    <img style={item.id === 5 ? {
                                        marginRight: '0',
                                        padding: '0',
                                        outline: '2px solid #fff'
                                    } : {}} src={item.image}/>

                                    <div className={'boost-item-text'}>

                                        <div className={'boost-item-text-top'}>
                                            <h6 className={item.id === 5 ? 'centered boost-item-span' : 'boost-item-h6'}>
                                                {item.name}
                                            </h6>

                                            <span
                                                className={item.id === 5 ? 'centered boost-item-span' : 'boost-item-span'}>
                                            {item.desc}
                                        </span>
                                        </div>

                                        <div className={'boost-item-text-bott'}>

                                            <div
                                                className={`${item.type === 1 ? 'nomargin' : ''} boost-item-text-bott-item`}
                                                style={item.id === 5 || item.id === 44 ? {display: 'none'} : {}}>
                                                {
                                                    item.id !== 1 && item.id !== 15 ?
                                                        <img src={vote_gold}/>
                                                        :
                                                        ""
                                                }
                                                <p>
                                                    {
                                                        item.id === 44 ?
                                                            ''
                                                            :
                                                            item.id === 1 ?
                                                                `Day ${(item?.available ? item?.step : item?.step - 1) || 0}`
                                                                :
                                                                item.id === 15 ?
                                                                    `LVL ${
                                                                        patriotsCount < 5 && patriotsCount >= 0 ?
                                                                            1
                                                                            :
                                                                            patriotsCount < 10 && patriotsCount >= 5 ?
                                                                                2
                                                                                :
                                                                                patriotsCount < 50 && patriotsCount >= 10 ?
                                                                                    3
                                                                                    :
                                                                                    patriotsCount < 100 && patriotsCount >= 50 ?
                                                                                        4
                                                                                        :
                                                                                        patriotsCount < 500 && patriotsCount >= 100 ?
                                                                                            5
                                                                                            :
                                                                                            5
                                                                    }`
                                                                    :
                                                                    item.id === 11 ?
                                                                        "100K"
                                                                        :
                                                                        item.id === 4 ?
                                                                            "300K"
                                                                            :
                                                                        item.id === 444 ?
                                                                            "250K"
                                                                            :
                                                                            '50K'
                                                    }
                                                </p>
                                            </div>

                                            <div className={'boost-item-text-bott-item'}
                                                 style={item.id === 5 ? {width: '100%'} : {}}>
                                                <p className={item.id === 5 ? 'centered boost-item-p' : 'boost-item-p'}>
                                                    {
                                                        item.id === 4 ?
                                                            client_is_premium == true ?
                                                                item.available ?
                                                                    'PUMP'
                                                                    :
                                                                    'PUMPED'
                                                                :
                                                                'Upgrade To Premium'
                                                            :
                                                            item.id === 444 ?
                                                                item.available ?
                                                                    'PUMP'
                                                                    :
                                                                    'PUMPED'
                                                                :
                                                            item.id === 44 ?
                                                                'Stay Tuned!'
                                                                :
                                                                item.id === 3 ?
                                                                    'Unlock'
                                                                    :
                                                                    item.id === 15 && patriotsCount < 5  ?
                                                                        'Not Enough Patriots'
                                                                        :
                                                                        item.id === 15 && patriotRewardLevelAvailable > 1  ?
                                                                            'Collect'
                                                                            :
                                                                            item.id === 5 ?
                                                                                item.available ?
                                                                                    'Vote'
                                                                                    :
                                                                                    'Voted'
                                                                                :
                                                                                item.available ?
                                                                                    'Collect'
                                                                                    :
                                                                                    'Collected'
                                                    }
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                    {
                                        item.id === 5 ?
                                            <img style={item.id === 5 ? {
                                                marginRight: '0',
                                                padding: '0',
                                                outline: '2px solid #fff'
                                            } : {}} src={item.image_two}/>
                                            :
                                            ''
                                    }

                                </div>

                            </div>
                        )
                    }

                </div>


            </div>


            {/* Patriots Block */}


            <div className={`${selectedItem === 1 ? 'active' : ''} farm-block`} id={'farm-block'}>

                <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal}/>


                <img src={patriot1} className={'rewards-main-img'}
                     style={{transform: 'scale(95%)', outline: '2px solid #ffffff', borderRadius: '1500px'}}/>

                <h2 className={'rewards-main-header'}>
                    Invite Patriots!
                </h2>

                <h5 className={'rewards-type'}>
                    Learn More
                </h5>


                <div className={'patriots-wrapper'}>

                    <div className={'temp-boost-container'}>

                        <div
                            className={`temp boost-item`}
                        >

                            <img src={gift_two}/>

                            <div className={'boost-item-text'}>

                                <div className={'boost-item-text-top'}>
                                    <h6>
                                        Invite Patriot
                                    </h6>

                                    <span>
                                        And Get Bonuses For Both
                                    </span>
                                </div>

                                <div className={'boost-item-text-bott'}>

                                    <div className={'boost-item-text-bott-item'}>
                                        <img src={vote_gold}/>
                                        <p>
                                            3K
                                        </p>
                                    </div>

                                    <div className={'boost-item-text-bott-item'}>
                                        <img src={farm_blue_img}/>
                                        <p>
                                            15% (<s>3%</s>) Bonus
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div
                            className={`temp boost-item`}
                        >

                            <img src={gift}/>

                            <div className={'boost-item-text'}>

                                <div className={'boost-item-text-top'}>
                                    <h6>
                                        Invite Patriot With Premium
                                    </h6>

                                    <span>
                                        And Get Bonuses For Both
                                    </span>
                                </div>

                                <div className={'boost-item-text-bott'}>

                                    <div className={'boost-item-text-bott-item'}>
                                        <img src={vote_gold}/>
                                        <p>
                                            5K
                                        </p>
                                    </div>

                                    <div className={'boost-item-text-bott-item'}>
                                        <img src={farm_blue_img}/>
                                        <p>
                                            20% (<s>5%</s>) Bonus
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                    <h5 className={'rewards-type'}>
                        Patriot Squad
                    </h5>

                    <div className={'patriots-list-container'}>

                        {

                            patriotsCount <= 0 ?
                                /*<div>
                                    <h5>
                                        Failed to load Patriots
                                    </h5>

                                    <button onClick={() => {
                                        // loadPatriots()
                                        setPatriotsLoadingBlock(true);
                                        setTimeout(() => {
                                            setPatriotsLoadingBlock(false);
                                        }, 80000)
                                    }}
                                            className={`${patriotsLoadingBlock ? 'disabled' : ''} btn primary`}
                                    >
                                        {patriotsLoadingBlock ? 'Loading' : 'Try Again'}
                                    </button>

                                </div>*/
                                ''
                                :
                                patriotsCount?.length !== 0 ?
                                    <div>

                                        <div className={'boost modal-content-chars-container'}
                                             style={{marginTop: '15px', marginBottom: '18px'}}>

                                            <div className={'farm modal-content-char-item'}>
                                                <img src={vote_gold}/>
                                                <h5>
                                                    {convertNumberToShortString(patriotsReward >= 0 ? patriotsReward : 0)}
                                                </h5>
                                            </div>

                                            <div className={'farm modal-content-char-item'}>
                                                <img src={farm_img}/>
                                                <h5>
                                                    {convertNumberToShortString(patriotsFarm >= 0 ? patriotsFarm : 0)}/H
                                                </h5>
                                            </div>

                                        </div>


                                        {
                                            patriotsList?.slice(0, 20).map((friend, i) =>
                                                <div className={'patriots-list-row'}>

                                                    <div className={'patriots-list-row-left'}>
                                                        <div className={'patriots-list-row-img-container'}>
                                                            <img src={
                                                                friend?.level === 1 ?
                                                                    patriot1
                                                                    :
                                                                    friend?.level === 2 ?
                                                                        voter2
                                                                        :
                                                                        friend?.level === 3 ?
                                                                            volunteer3
                                                                            :
                                                                            friend?.level === 4 ?
                                                                                election_observer4
                                                                                :
                                                                                friend?.level === 5 ?
                                                                                    personal_aide5
                                                                                    :
                                                                                    friend?.level === 6 ?
                                                                                        chief_of_staff6
                                                                                        :
                                                                                        friend?.level === 7 ?
                                                                                            campaign_manager7
                                                                                            :
                                                                                            friend?.level === 8 ?
                                                                                                trumpump_vp8
                                                                                                :
                                                                                                friend?.level === 9 ?
                                                                                                    trumpump_svp9
                                                                                                    :
                                                                                                    friend?.level === 10 ?
                                                                                                        trumpump_evp10
                                                                                                        :
                                                                                                        patriot1
                                                            } className={'patriots-list-row-img'}/>
                                                        </div>

                                                        <div className={'patriots-list-row-center-text'}>
                                                            <h6>
                                                                {friend?.username}
                                                            </h6>

                                                            <p>
                                                                {trumpRangs[friend?.level]}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className={'patriots-list-row-right'}>

                                                        <div className={'patriots boost-item-text-bott-item'}>
                                                            <img src={vote_gold}/>
                                                            <p>
                                                                {friend?.premium ? '5K' : '3K'}
                                                            </p>
                                                        </div>

                                                        <div className={'patriots boost-item-text-bott-item'}>
                                                            <img src={farm_img}/>
                                                            <p>
                                                                {
                                                                    friend?.is_premium === 1 ?
                                                                        // convertNumberToShortString(friend?.tokens_per_hour * 0.05)
                                                                        convertNumberToShortString(friend?.income)
                                                                        :
                                                                        // convertNumberToShortString(friend?.tokens_per_hour * 0.03)
                                                                        convertNumberToShortString(friend?.income)
                                                                }
                                                            </p>
                                                        </div>

                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                    :
                                    ''

                        }

                        <h6 style={{marginTop: '12px'}}>
                            {
                                patriotsCount > 10 ?
                                    `Top ${10} of ${patriotsCount} Patriots`
                                    :
                                    `You Invited ${patriotsCount > 0 ? patriotsCount : '0'} Patriots`
                            }
                        </h6>


                    </div>


                </div>


                <div className={'patriots-referral-container'}>

                    <a onClick={() => {
                        navigator.clipboard.writeText(referralCopyLink);
                        setSuccessMessage(true);
                        setSuccessMessageText('Copied to clipboard');
                        setTimeout(() => {
                            setSuccessMessage(false)
                        }, 2000)
                    }}>
                        <button className={'btn white round full'}>
                            Copy Referral Link
                        </button>
                    </a>

                    <a href={referralShareLink}>
                        <div className={'btn round primary'} style={{width: '50px'}}>
                            <img src={share} className={'patriots-referral-share-img'}/>
                        </div>
                    </a>

                </div>


            </div>


            {/* Info Block */}


            <div className={`${selectedItem === 5 ? 'active' : ''} farm-block`} id={'farm-block'}>

                <Head trumpLevelProps={trumpLevel} setLevelsModal={setLevelsModal}/>


                <img src={ava_main_season} className={'rewards-main-img'}
                     style={{transform: 'scale(95%)', outline: '2px solid #ffffff', borderRadius: '1500px'}}/>

                <h1 className={'rewards-main-header'} style={{fontWeight: '800'}}>
                    TrumPump
                </h1>

                <div className={'banner-btn'} style={{transform: 'scale(75%)'}}>
                    <h2>
                        SEASON I
                    </h2>
                </div>

                <h6 style={{maxWidth: '90%', margin: '12px 0'}}>
                    The rising US government debt presents a major concern for economies across the globe. Despite this,
                    authorities have yet to present clear plans to address the public debt in the foreseeable future.
                    Donald Trump, in collaboration with global cryptocurrency experts, proposes tackling this challenge
                    by scaling cryptocurrencies and revising cryptocurrency policies.
                </h6>

                <h3 className={'rewards-type'}>
                    ROADMAP
                </h3>


                <Roadmap info={true}/>


                {/*<div style={{width: '90vw', paddingBottom: '120px'}}>

                    <div className={'donate-wallets-container'}>

                        <h6>Support TrumPump! Click to copy</h6>

                        <div
                            className={'donate-wallets-item'}
                            onClick={() => {
                                navigator.clipboard.writeText('bc1q6nf0qaa3flqyasavuwq4mra9wun3xr5ye6rpt7');
                                setSuccessMessage(true);
                                setSuccessMessageText('Copied to clipboard');
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 2000)
                            }}
                        >
                            <div className={'donate-wallets-left'}>
                                BTC:
                            </div>
                            <div className={'donate-wallets-right'}>
                                bc1q6nf0qaa3flqyasavuwq4mra9wun3xr5ye6rpt7
                            </div>
                        </div>

                        <div
                            className={'donate-wallets-item'}
                            onClick={() => {
                                navigator.clipboard.writeText('0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271');
                                setSuccessMessage(true);
                                setSuccessMessageText('Copied to clipboard');
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 2000)
                            }}
                        >
                            <div className={'donate-wallets-left'}>
                                BSC:
                            </div>
                            <div className={'donate-wallets-right'}>
                                0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271
                            </div>
                        </div>

                        <div
                            className={'donate-wallets-item'}
                            onClick={() => {
                                navigator.clipboard.writeText('EQCfNWxSwGJ1IsGZ2Hnsdnda7f0oU6ea5JOeAZE3USznfYJ5');
                                setSuccessMessage(true);
                                setSuccessMessageText('Copied to clipboard');
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 2000)
                            }}
                        >
                            <div className={'donate-wallets-left'}>
                                TON:
                            </div>
                            <div className={'donate-wallets-right'}>
                                EQCfNWxSwGJ1IsGZ2Hnsdnda7f0oU6ea5JOeAZE3USznfYJ5
                            </div>
                        </div>

                        <div
                            className={'donate-wallets-item'}
                            onClick={() => {
                                navigator.clipboard.writeText('0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271');
                                setSuccessMessage(true);
                                setSuccessMessageText('Copied to clipboard');
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 2000)
                            }}
                        >
                            <div className={'donate-wallets-left'}>
                                ERC-20:
                            </div>
                            <div className={'donate-wallets-right'}>
                                0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271
                            </div>
                        </div>

                        <div
                            className={'donate-wallets-item'}
                            onClick={() => {
                                navigator.clipboard.writeText('TE3ynEZVEvXTYDuYDRXEXDtNSNYJWrr6QU');
                                setSuccessMessage(true);
                                setSuccessMessageText('Copied to clipboard');
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 2000)
                            }}
                        >
                            <div className={'donate-wallets-left'}>
                                TRC-20:
                            </div>
                            <div className={'donate-wallets-right'}>
                                TE3ynEZVEvXTYDuYDRXEXDtNSNYJWrr6QU
                            </div>
                        </div>

                    </div>

                </div>*/}


            </div>


            {/*<div className={'play-copyright'}>
                trumpump.app
            </div>*/}

            <img src={bg} className={`${rageMode ? 'light' : ''} play-bg-img`}/>

        </div>
    );
};

export default Play;