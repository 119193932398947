import React, {useEffect, useState} from 'react';
import './Welcome.css'
import banner from '../assets/banner.png'
import spinner from '../assets/spinner.svg'

import telegram from '../assets/rewards/telegram.png'
import x from '../assets/rewards/x.png'
import youtube from '../assets/rewards/youtube.png'

import logo_tp from '../assets/mono.svg'

const Welcome = ({visible}) => {

    return (

        <div className={`${visible ? 'visible' : ''} welcome-wrapper`}>

            <img src={banner} className={'welcome-banner light'} />

            {/*<h4>
                Welcome To
            </h4>

            <h1>
                TrumPump!
            </h1>*/}


            <div className={'welcome-spinner-container'}>
                <img src={spinner} className={'welcome-spinner'} />
                {/*<img src={logo_tp} className={'welcome-spinner-logo'} />*/}
            </div>


            {/*<div className={'banner-btn'}>
                <h2>
                    SEASON I
                </h2>
            </div>

            <h6>
                Let the new Chapter
                <br/>
                of Financial History begin!
            </h6>


            <div className={'welcome-bottom-wrapper'}>

                <p>
                    More info in Official Channels
                </p>

                <div className={'welcome-bottom-container'}>

                    <img src={telegram} />
                    <img src={x} />
                    <img src={youtube} />

                </div>

            </div>*/}


        </div>
    );
};

export default Welcome;