import React from 'react';
import vote_gold from "../assets/vote_gold.png";
import './PlayBalance.css'
import {convertNumberToShortString} from "./Play";

export const trumpLevelsSchema = [
    0,
    25000,
    50000,
    100000,
    1000000,
    5000000,
    10000000,
    50000000,
    100000000,
    500000000,
];

const PlayBalance = ({
                         trumpLevelProps,
                         counter,
                         counterTemp,
                         counterState,
                         tokensPerClick,
                         level,
                         tokensPerHour,
                         checkPointLoading,
                         isRageMode,
                         rageMode,
                         changeNav,
                         setBoostsOpen,
                         setLevelsModal
}) => {
    return (
        <div className={'play-page-block1-top'}>

            <div className={'play-play-params-wrapper'}>

                <div className={'play-play-params'}>
                    <div
                        className={'play-play-params-item'}
                        onClick={() => {
                            setBoostsOpen(true)
                        }}
                    >
                        <h5>
                            +{tokensPerClick}
                        </h5>
                        <p>
                            PUMP/Tap
                        </p>
                    </div>

                    <div
                        className={'play-play-params-item'}
                        onClick={() => setLevelsModal(true)}
                    >
                        <h5>
                            {trumpLevelProps.current}
                        </h5>
                        <p>
                            Level
                        </p>
                    </div>

                    <div
                        className={'play-play-params-item'}
                        onClick={() => {
                            setBoostsOpen(false);
                            changeNav(4)
                        }}
                    >
                        <h5>
                            +{convertNumberToShortString(tokensPerHour)}
                        </h5>
                        <p>
                            PUMP/Hour
                        </p>
                    </div>
                </div>


                <div className={'play-play-line-container'}>
                    <div
                        className={'play-play-line'}
                        style={{
                            width: `${(counter.current / (trumpLevelsSchema[trumpLevelProps.current])) * 100}%`,
                        }}
                    ></div>
                </div>

                {/*<div className={'play-play-line-params'}>
                        <p className={'bold'}>
                            30%
                        </p>
                        <p className={'bold'}>
                            PUMP!
                        </p>
                    </div>*/}


            </div>

            <div className={'vote-counter'}>
                <div className={'vote-counter-img-container'}>
                    <img className={`${counterState ? 'pressed' : ''} vote-counter-img`} src={vote_gold}/>
                </div>
                <h2
                    className={`${isRageMode ? 'active' : ''} mono`}
                >
                    {(counter?.current)?.toLocaleString(undefined, {maximumFractionDigits: 0})}
                </h2>
            </div>

        </div>
    );
};

export default PlayBalance;