import React, {useState, useEffect} from 'react';
import axios from "axios";
import flag from "../assets/flag.png";

import vote_gold from '../assets/vote_gold.png';


const DebtInvestModal = ({debtInvestModal, setDebtInvestModal, treasuryInvestment, urlNew, authToken, setReloadHandle}) => {

    const makeInvestment = (amount) => {

        axios.post(`${urlNew}/api/v1/treasury/invest`, {}, {
            headers: {
                "X-Auth-Token": authToken.current,
            }
        })
            .then((response) => {
                setReloadHandle(prev => prev + 1);

                setTimeout(() => {
                    setDebtInvestModal(false);
                }, 500)

            })
            .catch(error => {
                // alert(error);
                console.log(error);
                // setErrorMessage(true);
            });
    };


    return (
        <div className={`${debtInvestModal ? 'active' : ''} modal-wrapper`}>

            <div className={`${debtInvestModal ? 'active' : ''} modal-overlay`} onClick={() => setDebtInvestModal(false)}></div>

            <div className={`${debtInvestModal ? 'active' : ''} modal-container`}>

                <div className={`${debtInvestModal ? 'active' : ''} centered modal-content`}>

                    <h4 className={'modal-content-header'}>
                        Your Current Contribution
                    </h4>

                    <div className={'debt-modal-my-investment'}>
                        <img src={vote_gold}/>
                        <h2>
                            {(treasuryInvestment >= 0 ? treasuryInvestment : 0)?.toLocaleString()}
                        </h2>
                    </div>


                    <h5 style={{marginTop: '20px'}}>
                        Contribute More
                    </h5>

                    <div className={'modal-content-p'} style={{marginTop: '8px'}}>
                        Are you sure you want to use all your coins to pay off the debt?
                    </div>



                        <button
                            onClick={() => makeInvestment(0.25)}
                            className={'btn primary full round debt-modal-my-investment-option'}
                        >
                            Submit Contribution
                        </button>



                </div>

                <button
                    className={'modal btn round full white'}
                    onClick={() => {
                        setDebtInvestModal(false)
                    }}
                    style={{marginTop: '12px'}}
                >
                    Continue to PUMP
                </button>

            </div>

        </div>
    );
};

export default DebtInvestModal;