import React, {useEffect, useRef, useState} from 'react';
import './PlayNav.css'
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";


import farm_img from "../assets/nav/farm.png";
import squad from "../assets/nav/squad.png";
import play from "../assets/nav/play.png";
import rewards from "../assets/nav/rewards.png";
import about from "../assets/nav/about.png";
import {client_language_code} from "./Play";


import Lottie from 'react-lottie';
import animationDataFarm from '../assets/nav/farm_anim.json';
import animationDataPatriots from '../assets/nav/patriots_anim.json';
import animationDataPump from '../assets/nav/pump_anim.json';
import animationDataRewards from '../assets/nav/rewards_anim.json';
import animationDataAbout from '../assets/nav/about_anim.json';



const PlayNav = ({selectedItem, setSelectedItem, setBoostOpen}) => {

    /*const playRef = useRef(0);
    playRef.current = false;*/

    const [farmAnimState, setFarmAnimState] = useState(false)
    const [farmAnimDisabled, setFarmAnimDisabled] = useState(false)

    const [patriotsAnimState, setPatriotsAnimState] = useState(false)
    const [patriotsAnimDisabled, setPatriotsAnimDisabled] = useState(false)

    const [pumpAnimState, setPumpAnimState] = useState(false)
    const [pumpAnimDisabled, setPumpAnimDisabled] = useState(false)

    const [rewardsAnimState, setRewardsAnimState] = useState(false)
    const [rewardsAnimDisabled, setRewardsAnimDisabled] = useState(false)

    const [aboutAnimState, setAboutAnimState] = useState(false)
    const [aboutAnimDisabled, setAboutAnimDisabled] = useState(false)


    let defaultOptions = {
        animationData: '',
        loop: false,
        autoplay: false,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        mode:"cursor",
        actions: [
            {
                type: "click",
                forceFlag: false
            }
        ],
        isClickToPauseDisabled: true,
    };

    return (
        <div className={'play-nav-wrapper'}>

            <div className={'play-nav-container'}>

                <div
                    className={`${selectedItem === 4 ? 'active' : ''} play-nav-item`}
                    onClick={() => {

                    }}
                    onTouchStart={() => {
                        setSelectedItem(4);
                        setFarmAnimDisabled(true);
                        setFarmAnimState(true);
                        setTimeout(() => {
                            setFarmAnimState(false);
                            setFarmAnimDisabled(false);
                        }, 850)
                    }}
                    style={{pointerEvents: farmAnimDisabled ? 'none' : 'all'}}
                >
                    <div className={'play-nav-item-img-container'}>
                        <Lottie
                            options={{...defaultOptions, animationData: animationDataFarm}}
                            height={30}
                            width={30}
                            speed={1.1}
                            isStopped={!farmAnimState}
                        />
                    </div>
                    <span>
                            {
                                client_language_code == 'ru' ?
                                    'Фарм'
                                    :
                                    'Farm'
                            }
                    </span>
                </div>





                <div
                    className={`${selectedItem === 1 ? 'active' : ''} play-nav-item`}
                    onClick={() => {

                    }}
                    onTouchStart={() => {
                        setSelectedItem(1);
                        setPatriotsAnimDisabled(true);
                        setPatriotsAnimState(true);
                        setTimeout(() => {
                            setPatriotsAnimState(false);
                            setPatriotsAnimDisabled(false);
                        }, 850)
                    }}
                    style={{pointerEvents: patriotsAnimDisabled ? 'none' : 'all'}}
                >
                    <div className={'play-nav-item-img-container'}>
                        <Lottie
                            options={{...defaultOptions, animationData: animationDataPatriots}}
                            height={30}
                            width={30}
                            speed={1.2}
                            isStopped={!patriotsAnimState}
                        />
                    </div>
                    <span>
                            {
                                client_language_code == 'ru' ?
                                    'Патриоты'
                                    :
                                    'Patriots'
                            }
                    </span>
                </div>





                <div
                    className={`${selectedItem === 3 ? 'active' : ''} play-nav-item`}
                    onClick={() => {

                    }}
                    onTouchStart={() => {
                        setSelectedItem(3);
                        setPumpAnimDisabled(true);
                        setPumpAnimState(true);
                        setTimeout(() => {
                            setPumpAnimState(false);
                            setPumpAnimDisabled(false);
                        }, 850)
                    }}
                    style={{pointerEvents: pumpAnimDisabled ? 'none' : 'all'}}
                >
                    <div className={'gold play-nav-item-img-container'}>
                        <Lottie
                            options={{...defaultOptions, animationData: animationDataPump}}
                            height={42}
                            width={42}
                            speed={1.2}
                            isStopped={!pumpAnimState}
                        />
                    </div>
                </div>





                <div
                    className={`${selectedItem === 2 ? 'active' : ''} play-nav-item`}
                    onClick={() => {

                    }}
                    onTouchStart={() => {
                        setSelectedItem(2);
                        setRewardsAnimDisabled(true);
                        setRewardsAnimState(true);
                        setTimeout(() => {
                            setRewardsAnimState(false);
                            setRewardsAnimDisabled(false);
                        }, 850)
                    }}
                    style={{pointerEvents: rewardsAnimDisabled ? 'none' : 'all'}}
                >
                    <div className={'play-nav-item-img-container'}>
                        <Lottie
                            options={{...defaultOptions, animationData: animationDataRewards}}
                            height={30}
                            width={30}
                            speed={1.2}
                            isStopped={!rewardsAnimState}
                        />
                    </div>
                    <span>
                            {
                                client_language_code == 'ru' ?
                                    'Награды'
                                    :
                                    'Rewards'
                            }
                    </span>
                </div>





                <div
                    className={`${selectedItem === 5 ? 'active' : ''} play-nav-item`}
                    onClick={() => {

                    }}
                    onTouchStart={() => {
                        setSelectedItem(5);
                        setAboutAnimDisabled(true);
                        setAboutAnimState(true);
                        setTimeout(() => {
                            setAboutAnimState(false);
                            setAboutAnimDisabled(false);
                        }, 850)
                    }}
                    style={{pointerEvents: aboutAnimDisabled ? 'none' : 'all'}}
                >
                    <div className={'play-nav-item-img-container'}>
                        <Lottie
                            options={{...defaultOptions, animationData: animationDataAbout}}
                            height={30}
                            width={30}
                            speed={1.2}
                            isStopped={!aboutAnimState}
                        />
                    </div>
                    <span>
                            {
                                client_language_code == 'ru' ?
                                    'О нас'
                                    :
                                    'About'
                            }
                    </span>
                </div>

            </div>
        </div>
    );
};

export default PlayNav;