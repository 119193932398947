import React, {useEffect, useState} from 'react';
import './Home.css'
import './HomeNav.css'
import './HomeRoadmap.css'
import '../index.css'
import {Link} from "react-router-dom";
import banner from "../assets/banner_full.png";
import arrow from "../assets/home/arrow.svg";
import ava_main from "../assets/ava_main.png";
import spinner from "../assets/spinner.svg";
import telegram from "../assets/rewards/telegram.png";
import x from "../assets/rewards/x.png";
import youtube from "../assets/rewards/youtube.png";
import fb from "../assets/rewards/fb.png";

import vote_gold from "../assets/nav/rocket.png";

import wallet from "../assets/wallet.png";

import monogram from "../assets/logo.png";

import coin from "../assets/vote_gold.png";

import burger from "../assets/boosters/hat.png";
import energy_drink from "../assets/boosters/energy_drink.png";
import rage_mode from "../assets/boosters/rage_mode.png";
import tie_down from "../assets/boosters/tie_down.png";
import tie from "../assets/boosters/tie.png";



import patriot1 from '../assets/pfps/patriot1.png';
import voter2 from '../assets/pfps/voter2.png';
import volunteer3 from '../assets/pfps/volunteer3.png';
import election_observer4 from '../assets/pfps/election_observer4.png';
import personal_aide5 from '../assets/pfps/personal_aide5.png';
import chief_of_staff6 from '../assets/pfps/chief_of_staff6.png';
import campaign_manager7 from '../assets/pfps/campaign_manager7.png';
import trumpump_vp8 from '../assets/pfps/trumpump_vp8.png';
import trumpump_svp9 from '../assets/pfps/trumpump_svp9.png';
import trumpump_evp10 from '../assets/pfps/trumpump_evp10.png';

import Roadmap from "./Roadmap";



export const roadmapText = [

    {
        header: 'SEASON I',
        items: [

        ]
    }

]








const Home = () => {




    const [waitMessage, setWaitMessage] = useState(false);
    const [waitMessageText, setWaitMessageText] = useState('Loading..');
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('Copied to clipboard');
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('Error =/');


    useEffect(() => {
        setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);
        setTimeout(() => {
            setSuccessMessageText('Copied to clipboard');
        }, 4000)
    }, [successMessage]);


    useEffect(() => {
        setTimeout(() => {
            setWaitMessage(false);
        }, 3000);
        setTimeout(() => {
            setWaitMessageText('Loading..');
        }, 4000)
    }, [waitMessage]);


    useEffect(() => {
        setTimeout(() => {
            setErrorMessage(false);
        }, 3000);
        setTimeout(() => {
            setErrorMessageText('Error =/');
        }, 4000)
    }, [errorMessage]);



    return (
        <div className={'home-page'}>

            <div className={'messages-container'}>

                <div className={`${waitMessage ? 'active' : ''} wait message-item`}>
                    {waitMessageText}
                </div>

                <div className={`${successMessage ? 'active' : ''} success message-item`}>
                    {successMessageText}
                </div>

                <div className={`${errorMessage ? 'active' : ''} error message-item`}>
                    {errorMessageText}
                </div>

            </div>

            <div className={'home-page-block1'}>

                <div className={'home-nav-wrapper'}>

                    <div className={'home-nav-left-container'}>

                        <div className={'home-nav-play-btn'}>
                            Join Us
                        </div>

                        <a href={'https://t.me/trumpump_app'} className={'home-nav-item-soc-img'}>
                            <img src={telegram} />
                        </a>

                        <a href={'https://x.com/trumpump_app'} className={'home-nav-item-soc-img'}>
                            <img src={x} />
                        </a>

                        <a href={'https://www.instagram.com/trumpump_app/'} className={'home-nav-item-soc-img'}>
                            <img src={youtube}/>
                        </a>

                    </div>

                    <div className={'home-nav-center'}>

                        <img src={monogram} />

                        {/*<h1>
                            TRUMPUMP
                        </h1>*/}

                    </div>

                    <div className={'home-nav-right-container'}>

                        <a href={'https://t.me/real_DonaldJTrump'} className={'home-nav-item-soc-img'}>
                            <img src={telegram} />
                        </a>

                        <a href={'https://www.facebook.com/DonaldTrump'} className={'home-nav-item-soc-img'}>
                            <img src={x} />
                        </a>

                        <a href={'https://www.instagram.com/realdonaldtrump'} className={'home-nav-item-soc-img'}>
                            <img src={youtube} />
                        </a>

                        <a href={'https://www.facebook.com/DonaldTrump'} className={'home-nav-item-soc-img'}>
                            <img src={fb} />
                        </a>

                        <div className={'red home-nav-play-btn'}>
                            Join Trump
                        </div>

                        {/*<div className={'home-nav-play-btn'}>
                            Play Now!
                        </div>*/}

                    </div>

                </div>


                <div className={'home visible welcome-wrapper'}>

                    <img src={banner} className={'home welcome-banner'} />

                    <h4>
                        Lend your support to the
                    </h4>

                    <h1>
                        True Crypto President
                        <br/>
                        Donald Trump
                    </h1>

                    <a className={'banner-btn'} href={'https://t.me/trumpumpbot'} style={{marginTop: '40px'}}>
                        <h2>
                            Play Now!
                        </h2>
                    </a>


                    <div className={'home-banner-bottom'}>
                        <h5>
                            Let the new Chapter
                            <br/>
                            of Financial History begin!
                        </h5>

                        <img src={arrow} />
                    </div>


                </div>



                <Roadmap info={false}/>



                <div className={'home-pfps-wrapper'}>

                    <div className={'home-pfps-container'}>
                        <img src={patriot1} />
                        <img src={voter2} />
                        <img src={volunteer3} />
                        <img src={election_observer4} />
                        <img src={personal_aide5} />
                        <img src={chief_of_staff6} />
                        <img src={campaign_manager7} />
                        <img src={trumpump_vp8} />
                        <img src={trumpump_svp9} />
                        <img src={trumpump_evp10} />
                    </div>

                    <div className={'donate-wallets-wrapper'}>

                        <div className={'donate-wallets-container'}>

                            <h6>Support TrumPump! Click to copy</h6>

                            <div
                                className={'donate-wallets-item'}
                                onClick={() => {
                                    navigator.clipboard.writeText('bc1q6nf0qaa3flqyasavuwq4mra9wun3xr5ye6rpt7');
                                    setSuccessMessage(true);
                                    setSuccessMessage('Copied to clipboard');
                                    setTimeout(() => {
                                        setSuccessMessage(false)
                                    }, 2000)
                                }}
                            >
                                <div className={'donate-wallets-left'}>
                                    BTC:
                                </div>
                                <div className={'donate-wallets-right'}>
                                    bc1q6nf0qaa3flqyasavuwq4mra9wun3xr5ye6rpt7
                                </div>
                            </div>

                            <div
                                className={'donate-wallets-item'}
                                onClick={() => {
                                    navigator.clipboard.writeText('0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271');
                                    setSuccessMessage(true);
                                    setSuccessMessage('Copied to clipboard');
                                    setTimeout(() => {
                                        setSuccessMessage(false)
                                    }, 2000)
                                }}
                            >
                                <div className={'donate-wallets-left'}>
                                    BSC:
                                </div>
                                <div className={'donate-wallets-right'}>
                                    0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271
                                </div>
                            </div>

                            <div
                                className={'donate-wallets-item'}
                                onClick={() => {
                                    navigator.clipboard.writeText('EQCfNWxSwGJ1IsGZ2Hnsdnda7f0oU6ea5JOeAZE3USznfYJ5');
                                    setSuccessMessage(true);
                                    setSuccessMessage('Copied to clipboard');
                                    setTimeout(() => {
                                        setSuccessMessage(false)
                                    }, 2000)
                                }}
                            >
                                <div className={'donate-wallets-left'}>
                                    TON:
                                </div>
                                <div className={'donate-wallets-right'}>
                                    EQCfNWxSwGJ1IsGZ2Hnsdnda7f0oU6ea5JOeAZE3USznfYJ5
                                </div>
                            </div>

                            <div
                                className={'donate-wallets-item'}
                                onClick={() => {
                                    navigator.clipboard.writeText('0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271');
                                    setSuccessMessage(true);
                                    setSuccessMessage('Copied to clipboard');
                                    setTimeout(() => {
                                        setSuccessMessage(false)
                                    }, 2000)
                                }}
                            >
                                <div className={'donate-wallets-left'}>
                                    ERC-20:
                                </div>
                                <div className={'donate-wallets-right'}>
                                    0x4B4C79A19D33F6f9010489fB09c7Ac3f78E69271
                                </div>
                            </div>

                            <div
                                className={'donate-wallets-item'}
                                onClick={() => {
                                    navigator.clipboard.writeText('TE3ynEZVEvXTYDuYDRXEXDtNSNYJWrr6QU');
                                    setSuccessMessage(true);
                                    setSuccessMessage('Copied to clipboard');
                                    setTimeout(() => {
                                        setSuccessMessage(false)
                                    }, 2000)
                                }}
                            >
                                <div className={'donate-wallets-left'}>
                                    TRC-20:
                                </div>
                                <div className={'donate-wallets-right'}>
                                    TE3ynEZVEvXTYDuYDRXEXDtNSNYJWrr6QU
                                </div>
                            </div>

                        </div>


                        <div className={'home-bottom-wallets-right'}>

                            {/*<h3>Donate</h3>*/}

                            <img className={'home-bottom-wallet-img'} src={wallet} />
                        </div>


                    </div>


                    <div className={'bottom-socs'}>


                        <div className={'socs home-nav-left-container'}>

                            <div className={'home-nav-play-btn'}>
                                Join Us
                            </div>

                            <a href={'https://t.me/trumpump_app'} className={'home-nav-item-soc-img'}>
                                <img src={telegram} />
                            </a>

                            <a href={'https://x.com/trumpump_app'} className={'home-nav-item-soc-img'}>
                                <img src={x} />
                            </a>

                            <a href={'https://www.instagram.com/trumpump_app/'} className={'home-nav-item-soc-img'}>
                                <img src={youtube}/>
                            </a>

                        </div>


                        <div className={'socs home-nav-right-container'}>

                            <div className={'red home-nav-play-btn'}>
                                Join Trump
                            </div>

                            <a href={'https://t.me/real_DonaldJTrump'} className={'home-nav-item-soc-img'}>
                                <img src={telegram} />
                            </a>

                            <a href={'https://www.facebook.com/DonaldTrump'} className={'home-nav-item-soc-img'}>
                                <img src={x} />
                            </a>

                            <a href={'https://www.instagram.com/realdonaldtrump'} className={'home-nav-item-soc-img'}>
                                <img src={youtube} />
                            </a>

                            <a href={'https://www.facebook.com/DonaldTrump'} className={'home-nav-item-soc-img'}>
                                <img src={fb} />
                            </a>

                        </div>


                    </div>

                    <p className={'home-bottom-p'}>
                        <br/>
                        Trumpump was created to support the true Crypto President, Donald Trump. The project has not
                        been coordinated with or endorsed by Donald Trump and was made by a group of like-minded
                        developers. We share a common vision for financial future and provide grassroots support.
                        <br/>
                        <br/>
                        Contact us: pr@trumpump.app
                        <br/>
                        © 2024 Trumpump. All rights reserved.
                    </p>

                </div>


            </div>


        </div>
    );
};

export default Home;