import React from 'react';
import bronze_bar from "../assets/farm/bars/bronze.png";
import silver_bar from "../assets/farm/bars/silver.png";
import gold_bar from "../assets/farm/bars/gold.png";
import platinum_bar from "../assets/farm/bars/platinum.png";
import diamond_bar from "../assets/farm/bars/diamond.png";
import energy_img from "../assets/nav/energy.png";
import vote_gold from "../assets/vote_gold.png";
import farm_img from "../assets/nav/farm.png";
import {farm} from "../FarmSchema";
import {client_language_code, convertNumberToShortString} from "./Play";
import './Play.css';

const FarmModal = (
    {
        friendsUserArray,
        patriotsCount,
        trumpLevel,

        updateFarmRequest,

        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        waitMessage,
        setWaitMessage,
        counter,
        farmUpdateLoading,
        farmUserArrayUnfiltered,
        farmUserArray,
        farmModalItem,
        farmModal,
        setFarmModal
    }
) => {




    return (
        <div className={`${farmModal ? 'active' : ''} modal-wrapper`}>

            <div className={`${farmModal ? 'active' : ''} modal-overlay`} onClick={() => setFarmModal(false)}></div>

            <div className={`${farmModal ? 'active' : ''} ${
                farmModalItem?.class === 1 || farmModalItem?.type === 4 ?
                    'bronze'
                    :
                    farmModalItem?.class === 2 ?
                        'silver'
                        :
                        farmModalItem?.class === 3 ?
                            'gold'
                            :
                            farmModalItem?.class === 4 ?
                                'platinum'
                                :
                                farmModalItem?.class === 5 ?
                                    'diamond'
                                    :
                                    ''
            } ${farmModalItem?.id === 100 ? 'disabled' : ''} farm modal-container`}>

                <div className={`${farmModal ? 'active' : ''} centered modal-content`}>

                    {
                        farmModalItem?.type === 1 ?
                            <h3 className={'modal-content-header'}>
                                MAGA
                            </h3>
                            :
                            <h3 className={'modal-content-header'}>
                                {farmModalItem?.name}
                            </h3>
                    }
                    {
                        farmModalItem?.type === 1 ?
                            <h4 className={'modal-content-header-small'}>
                                {
                                    farmUserArrayUnfiltered[farmModalItem?.id] === undefined ?
                                        `${client_language_code == 'ru' ? 'Заблокировано' : 'Locked'}`
                                        :
                                        farmModalItem?.name
                                    // `${client_language_code == 'ru' ? 'Разблокировано' : 'Unlocked'}`

                                }
                            </h4>
                            :
                            <h4 className={'modal-content-header-small'}>
                                LVL {farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.level}
                            </h4>
                    }

                    <div className={`${farmModalItem?.type === 1 ? 'nopadding' : ''} farm modal-content-img-container`}>
                        <img className={`farm modal-content-img`} src={
                            (farmUserArrayUnfiltered[farmModalItem?.id] === undefined) && farmModalItem?.type === 1 ?
                                farmModalItem?.class === 1 || farmModalItem?.type === 4 ?
                                    bronze_bar
                                    :
                                    farmModalItem?.class === 2 ?
                                        silver_bar
                                        :
                                        farmModalItem?.class === 3 ?
                                            gold_bar
                                            :
                                            farmModalItem?.class === 4 ?
                                                platinum_bar
                                                :
                                                farmModalItem?.class === 5 ?
                                                    diamond_bar
                                                    :
                                                    ''
                                :
                                farmModalItem?.img}
                        />
                        <div className={'farm-item-img-container-shadow'}></div>
                    </div>

                    {
                        farmModalItem?.type === 1 ?
                            <p className={'farm modal-content-desc-p'}>
                                {
                                    client_language_code == 'ru' ?
                                        'Разблокируйте все карточки и получайте почасовой доход.'
                                        :
                                        'Unlock all cards and get hourly income.'
                                }
                                <br/>
                                {
                                    client_language_code == 'ru' ?
                                        'Доход в оффлайне 4 часа.'
                                        :
                                        'Cards work offline for 4 hours.'
                                }
                            </p>
                            :
                            <p className={'farm modal-content-desc-p'}>
                                {farmModalItem?.description}
                            </p>
                    }

                    <div className={'farm modal-content-chars-container'}>

                        <div className={'farm modal-content-char-item'}>
                            <img src={energy_img}/>
                            <h5>
                                {/*{farmModalItem?.type === 1 ? 'Infinite' : '4H'}*/}
                                0
                            </h5>
                        </div>

                        <div className={'farm modal-content-char-item'}>
                            <img src={vote_gold}/>
                            <h5>
                                {
                                    farmUserArray?.find(farm => farm.id === farmModalItem?.id) ?
                                        convertNumberToShortString(farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.price)
                                        :
                                        convertNumberToShortString(farmModalItem?.farmLevels[0]?.price)
                                }
                            </h5>
                        </div>

                        <div className={'farm modal-content-char-item'}>
                            <img src={farm_img}/>
                            <h5>
                                {
                                    farmUserArray?.find(farm => farm.id === farmModalItem?.id) ?
                                        farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.farm?.toLocaleString(undefined, {maximumFractionDigits: 0})
                                        :
                                        farmModalItem?.farmLevels[0]?.farm?.toLocaleString(undefined, {maximumFractionDigits: 0})
                                }
                            </h5>
                        </div>

                    </div>


                </div>

                <button
                    className={`${
                        farmUpdateLoading ?
                            'disabled'
                            :
                            farmModalItem?.type === 1 && ((farm?.filter(farm => (farm.class === (farmModalItem?.class - 1)) && (farmUserArray?.find(find => find.id === farm.id)?.level >= 1)))?.length < 3) && farmModalItem?.class !== 1 && farmModalItem?.activation_trigger_type === 1 ?
                                'disabled'
                                :
                                (farmModalItem?.activation_trigger_type === 2) && (farmUserArray?.find(find => (find.id === farmModalItem?.activation_trigger_goal) && (find.level < farmModalItem?.activation_trigger_amount))) ?
                                    'disabled'
                                    :
                                    farmModalItem?.activation_trigger_type === 3 && trumpLevel.current < farmModalItem?.activation_trigger_goal ?
                                        'disabled'
                                        :
                                        (farmModalItem?.activation_trigger_type === 4 && patriotsCount < farmModalItem?.activation_trigger_goal) ?
                                            'disabled'
                                            :
                                            (farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.level > 0) && (farmModalItem?.type === 1) ?
                                                'disabled'
                                                :
                                                (counter.current < farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.price) || waitMessage ?
                                                    'disabled'
                                                    :
                                                    ''
                    } ${farmModalItem?.id === 100 ? 'disabled' : ''} modal btn round full white`}
                    onClick={() => {
                        updateFarmRequest(
                            farmModalItem?.id,
                            farmModalItem?.level,
                            farmModalItem?.price,
                            farmModalItem?.farm);
                    }}
                >
                    {
                        farmModalItem?.type === 1 && ((farm?.filter(farm => (farm.class === (farmModalItem?.class - 1)) && (farmUserArray?.find(find => find.id === farm.id)?.level >= 1)))?.length < 3) && farmModalItem?.class !== 1 && farmModalItem?.activation_trigger_type === 1 ?
                            `${client_language_code == 'ru' ? 'Требуется' : 'Required'}: 3 ${farmModalItem?.class === 2 ? 'Bronze' : farmModalItem?.class === 3 ? 'Silver' : farmModalItem?.class === 4 ? 'Gold' : farmModalItem?.class === 5 ? 'Platinum' : ''} Cards`
                            :
                            (farmModalItem?.activation_trigger_type === 2 && (farmUserArray?.find(find => (find.id === farmModalItem?.activation_trigger_goal) && (find.level < farmModalItem?.activation_trigger_amount)))) ?
                                `${client_language_code == 'ru' ? 'Требуется' : 'Required'}: ${farm.find(find => find.id === farmModalItem?.activation_trigger_goal).name} ${farmModalItem?.activation_trigger_amount} LVL`
                                :
                                farmModalItem?.activation_trigger_type === 3 && trumpLevel.current < farmModalItem?.activation_trigger_goal ?
                                    `${client_language_code == 'ru' ? 'Требуется' : 'Required'}: ${farmModalItem?.activation_trigger_goal} ${client_language_code == 'ru' ? 'Уровень' : 'Level'}`
                                    :
                                    (farmModalItem?.activation_trigger_type === 4 && patriotsCount < farmModalItem?.activation_trigger_goal) ?
                                        `${client_language_code == 'ru' ? 'Требуется' : 'Required'}: ${farmModalItem?.activation_trigger_goal} Patriots`
                                        :
                                        (counter.current >= farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.price) || (farmModalItem?.type === 1 && farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level === 1) ?
                                            farmModalItem?.farmLevels[farmUserArray?.find(farm => farm.id === farmModalItem?.id)?.level]?.level > 0 ?
                                                farmModalItem?.type === 1 ?
                                                    `${client_language_code == 'ru' ? 'Куплено' : 'Collected'}`
                                                    :
                                                    `${client_language_code == 'ru' ? 'Улучшить' : 'Upgrade'}`
                                                :
                                                `${client_language_code == 'ru' ? 'Купить' : 'Buy'}`
                                            :
                                            `${client_language_code == 'ru' ? 'Недостаточно средств' : 'Insufficient Funds'}`

                    }
                </button>

            </div>

        </div>
    );
};

export default FarmModal;