import React from 'react';
import './Head.css'
import {tg} from "./Play";
// import wallet from '../assets/wallet.svg'
import wallet from '../assets/wallet.png'
import patriot1 from '../assets/pfps/patriot1.png';
import voter2 from '../assets/pfps/voter2.png';
import volunteer3 from '../assets/pfps/volunteer3.png';
import election_observer4 from '../assets/pfps/election_observer4.png';
import personal_aide5 from '../assets/pfps/personal_aide5.png';
import chief_of_staff6 from '../assets/pfps/chief_of_staff6.png';
import campaign_manager7 from '../assets/pfps/campaign_manager7.png';
import trumpump_vp8 from '../assets/pfps/trumpump_vp8.png';
import trumpump_svp9 from '../assets/pfps/trumpump_svp9.png';
import trumpump_evp10 from '../assets/pfps/trumpump_evp10.png';

export const trumpRangs = [
    'Patriot',
    'Patriot',
    'Voter',
    'Volunteer',
    'Election Observer',
    'Personal Aide',
    'Chief of Staff',
    'Campaign Manager',
    'Trumpump VP',
    'Trumpump SVP',
    'Trumpump EVP',
];

const Head = ({trumpLevelProps, setLevelsModal}) => {
    return (
        <div className={'head-wrapper'}>

            <div className={'head-profile-container'} onClick={() => setLevelsModal(true)}>

                <div className={'head-profile-img-container'}>
                    <img className={`${trumpLevelProps.current === 1 ? 'active' : ''} head-profile-img`} src={patriot1} />
                    <img className={`${trumpLevelProps.current === 2 ? 'active' : ''} head-profile-img`} src={voter2} />
                    <img className={`${trumpLevelProps.current === 3 ? 'active' : ''} head-profile-img`} src={volunteer3} />
                    <img className={`${trumpLevelProps.current === 4 ? 'active' : ''} head-profile-img`} src={election_observer4} />
                    <img className={`${trumpLevelProps.current === 5 ? 'active' : ''} head-profile-img`} src={personal_aide5} />
                    <img className={`${trumpLevelProps.current === 6 ? 'active' : ''} head-profile-img`} src={chief_of_staff6} />
                    <img className={`${trumpLevelProps.current === 7 ? 'active' : ''} head-profile-img`} src={campaign_manager7} />
                    <img className={`${trumpLevelProps.current === 8 ? 'active' : ''} head-profile-img`} src={trumpump_vp8} />
                    <img className={`${trumpLevelProps.current === 9 ? 'active' : ''} head-profile-img`} src={trumpump_svp9} />
                    <img className={`${trumpLevelProps.current === 10 ? 'active' : ''} head-profile-img`} src={trumpump_evp10} />
                    <div className={'head-profile-img-shadow'}></div>
                </div>

                <div className={'head-profile-text'}>
                    <h6>
                        {tg.initDataUnsafe?.user?.first_name} {tg.initDataUnsafe?.user?.last_name}
                    </h6>
                    <p>
                        {trumpRangs[trumpLevelProps.current]}
                    </p>
                </div>

            </div>

            <div className={'head-wallet-container'}>
                <div className={'head-wallet-img-container'}>
                    <img className={'head-wallet-img'} src={wallet} />
                </div>
            </div>

        </div>
    );
};

export default Head;